import { EntityId, UserEntity } from 'fwi-fe-types';

import {
  AppState,
  ReadonlyEntityList,
  ListViewUser,
  UsersState,
} from 'appTypes';
import { formatUserName } from 'utils/users';

import { adapter } from './schema';

const getUsersState = (state: AppState): UsersState => state.users;

export const {
  selectById: getUserById,
  selectAll: getUsers,
  selectEntities: getUserEntities,
  selectIds: getUserIds,
  selectTotal: getTotalUsers,
} = adapter.getSelectors(getUsersState);

/**
 * Gets a user by id for the list view and properties panel.
 *
 * @param state - The top-level store state.
 * @param entityId - The user's id
 * @returns the {@link ListViewUser} or undefined.
 */
export const getListViewUserById = (
  state: AppState,
  entityId: EntityId
): Readonly<ListViewUser> | undefined => {
  const user = getUserById(state, entityId);
  if (!user) {
    return;
  }

  let description = '';
  if ('description' in user) {
    ({ description } = user);
  }

  const { licenseType, createdOn, modifiedOn, lastLoginOn } = user;

  return {
    entityId,
    name: formatUserName(user),
    type: 'user',
    description,
    createdOn,
    modifiedOn,
    licenseType,
    lastLoginOn,
  };
};

/**
 *
 * @param state - The top-level store state.
 * @param userIds - The list ids of the user to find
 * @returns a list of all users that exist in state with the provided ids.
 */
export const getUsersByIds = (
  state: AppState,
  userIds: readonly EntityId[]
): ReadonlyEntityList<UserEntity> =>
  userIds.reduce<UserEntity[]>((list, userId) => {
    const user = getUserById(state, userId);
    if (user) {
      list.push(user);
    }

    return list;
  }, []);

/**
 *
 * @param state - The top-level store state.
 * @param userId - The userId to find if it is loading.
 */
export const isLoadingUser = (state: AppState, userId: EntityId): boolean =>
  getUsersState(state).loadingIds.includes(userId);

/**
 *
 * @param state - The top-level store state.
 * @param groupId - The groupId that was used to fetch the users.
 */
export const isLoadingUsersByGroupId = (
  state: AppState,
  groupId: EntityId
): boolean => getUsersState(state).loadingByGroupIds.includes(groupId);

/**
 *
 * @param state The top-level store state.
 * @param userId The userId that should be checked
 * @returns `true` if the provided user id is being deleted
 */
export const isUserDeleting = (state: AppState, userId: EntityId): boolean =>
  getUsersState(state).loadingDeleteIds.includes(userId);
