import { REACT_APP_BE_ORIGIN } from 'constants/env';

import { COMPANIES_PATH } from './constants';

export const CUSTOMERS_BASE_ENDPOINT = `${REACT_APP_BE_ORIGIN}/customer-management/v1/${COMPANIES_PATH}`;
export const CUSTOMERS_ENDPOINT = `${CUSTOMERS_BASE_ENDPOINT}/customers`;
export const CUSTOMERS_ID_ENDPOINT = `${CUSTOMERS_ENDPOINT}/:customerId`;
export const CUSTOMERS_COUNT_ENDPOINT = `${CUSTOMERS_ENDPOINT}/count`;
export const CUSTOMERS_ACTIVATE_ENDPOINT = `${CUSTOMERS_ID_ENDPOINT}/activate`;
export const CUSTOMERS_SUSPEND_ENDPOINT = `${CUSTOMERS_ID_ENDPOINT}/suspend`;
export const CUSTOMERS_DEACTIVATE_ENDPOINT = `${CUSTOMERS_ID_ENDPOINT}/deactivate`;
export const CUSTOMERS_USAGE_ENDPOINT = `${CUSTOMERS_ID_ENDPOINT}/usage`;
