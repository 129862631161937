import { FormattedMessage, useIntl } from 'fwi-fe-components';
import { FormEventHandler, ReactElement } from 'react';

import { BrandedTextField } from './BrandedTextField';
import { UnifiedForm } from './UnifiedForm';
import styles from './UnifiedLogin.module.scss';

export interface UnifiedLoginProps {
  error?: string;
  email: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  setEmail(email: string): void;
  loading: boolean;
}

export function UnifiedLogin({
  error,
  email,
  onSubmit,
  setEmail,
  loading,
}: UnifiedLoginProps): ReactElement {
  const intl = useIntl();

  return (
    <UnifiedForm
      name="identityProvider"
      heading={<FormattedMessage messageId="Login.SignIn" />}
      loading={loading}
      onSubmit={onSubmit}
    >
      {error && (
        <span id="login-error" role="alert" className={styles.message}>
          <FormattedMessage messageId={error} />
        </span>
      )}
      <BrandedTextField
        id="idp-email-address"
        type="email"
        required
        autoFocus
        value={email}
        label={<FormattedMessage messageId="Login.Email" />}
        onChange={(event) => setEmail(event.currentTarget.value)}
        placeholder={intl.formatMessage({ id: 'Login.EmailPlaceholder' })}
        disabled={loading}
      />
    </UnifiedForm>
  );
}
