import {
  CircularProgress,
  DigitalSignageProductIcon,
  EmailAndFeedsProductIcon,
  GridIcon,
  MenuItem,
  useEmailAndFeedsRedirect,
} from 'fwi-fe-components';
import type { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  dashboardSelectedFromDropdown,
  emailAndFeedsSelected,
  useAppDispatch,
} from 'appState';
import CustomLink from 'components/CustomLink';
import FormattedMessage from 'components/FormattedMessage';
import { useDashboardUrl } from 'hooks/useDashboardUrl';
import { isAlerts, isCustomers, isReporting } from 'utils/routes';

export interface DashboardMenuItemProps {
  id: string;
}

export default function DashboardMenuItem({
  id,
}: Readonly<DashboardMenuItemProps>): ReactElement {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const href = useDashboardUrl();
  const { loading, redirect, isEmailAndFeedsAvailable } =
    useEmailAndFeedsRedirect();

  if (!isEmailAndFeedsAvailable) {
    return (
      <MenuItem
        id={id}
        icon={<GridIcon />}
        href={href}
        onClick={() => {
          dispatch(dashboardSelectedFromDropdown());
        }}
        linkComponent={CustomLink}
      >
        <FormattedMessage messageId="Dashboard" />
      </MenuItem>
    );
  }

  return (
    <>
      <MenuItem
        id="account-dropdown-ds-dashboard"
        to={href}
        icon={<DigitalSignageProductIcon />}
        disabled={loading}
        onClick={() => {
          dispatch(dashboardSelectedFromDropdown());
        }}
        linkComponent={Link}
      >
        <FormattedMessage messageId="DigitalSignageProduct" />
      </MenuItem>
      <MenuItem
        id="account-dropdown-ef-dashboard"
        icon={
          loading ? (
            <CircularProgress
              id="account-dropdown-loading-ef-dashboard"
              centered={false}
              className="account-dropdown__progress"
            />
          ) : (
            <EmailAndFeedsProductIcon />
          )
        }
        disabled={loading}
        onClick={(event) => {
          event.stopPropagation();

          let location = 'Admin Center';
          if (isReporting(pathname)) {
            location = 'Reporting';
          } else if (isCustomers(pathname)) {
            location = 'Customers';
          } else if (isAlerts(pathname)) {
            location = 'Alerts';
          }

          dispatch(
            emailAndFeedsSelected({ location, category: 'My Harmony Menu' })
          );
          redirect();
        }}
      >
        <FormattedMessage messageId="EmailAndFeedsProduct" />
      </MenuItem>
    </>
  );
}
