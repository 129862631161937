import { MouseEvent, ReactElement, useCallback, useRef } from 'react';
import cn from 'classnames';
import CSSTransition, {
  CSSTransitionClassNames,
} from 'react-transition-group/CSSTransition';
import { Button } from 'fwi-fe-components';

import {
  isMobile,
  patchAlert,
  popToast,
  undoAlertDeleted,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { Toast as ToastDefinition } from 'appTypes';
import FormattedMessage from 'components/FormattedMessage';

import styles from './Toast.module.scss';

const classNames: CSSTransitionClassNames = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive,
};
const timeout = { enter: 200, exit: 150 };

export interface ToastProps {
  toast: ToastDefinition;

  // these get cloned in by thte `TransitionGroup`
  in?: boolean;
  onExited?(): void;
}

/**
 * This is a type guard to help determine what type of toast has been provided.
 */
function match<Keys extends keyof ToastDefinition>(
  toast: ToastDefinition,
  messageId: string
): toast is ToastDefinition & Required<Pick<ToastDefinition, Keys>> {
  return toast.messageId === messageId;
}

export default function Toast({ toast, ...props }: ToastProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const mobile = useAppSelector(isMobile);
  const dispatch = useAppDispatch();
  const handleClick = useCallback(
    async (_event: MouseEvent<HTMLButtonElement>) => {
      if (match<'entityId'>(toast, 'Toasts.DeleteAlertSuccess')) {
        const result = await dispatch(
          patchAlert({ id: toast.entityId, isAlertActive: true })
        );
        if (patchAlert.fulfilled.match(result)) {
          dispatch(undoAlertDeleted());
        }
      } else if (process.env.NODE_ENV !== 'production') {
        /* eslint-disable no-console */
        console.error('Current toast does not have a click handler defined:');
        console.error(toast);
        /* eslint-enable no-console */
      }

      dispatch(popToast());
    },
    [dispatch, toast]
  );
  const { messageId, messageValues, actionMessageId, theme } = toast;

  return (
    <CSSTransition
      nodeRef={nodeRef}
      classNames={classNames}
      timeout={timeout}
      {...props}
    >
      <div
        id="active-toast"
        ref={nodeRef}
        className={cn(styles.container, {
          [styles.containerMobile]: mobile,
          [styles.containerAction]: actionMessageId,
          [styles.containerError]: theme === 'error',
        })}
        tabIndex={-1}
      >
        <FormattedMessage
          messageId={messageId}
          messageValues={messageValues}
          component="p"
          className={styles.message}
        />
        {actionMessageId && (
          <Button
            id="active-toast-action"
            onClick={handleClick}
            className={styles.action}
            theme="clear"
            isOnDarkBackground
          >
            <FormattedMessage messageId={actionMessageId} />
          </Button>
        )}
      </div>
    </CSSTransition>
  );
}
