import { ReactElement, useCallback, useState } from 'react';
import { DialogContent, DialogFooter, DialogButton } from 'fwi-fe-components';
import { EntityId } from 'fwi-fe-types';

import {
  getUserCompanies,
  isMobile,
  patchCurrentUser,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import FormattedMessage from 'components/FormattedMessage';

import styles from './UserSettingsContent.module.scss';
import UserAccounts from './UserAccounts';
import UserAnalytics from './UserAnalytics';

export interface UserSettingsContentProps {
  dialogId: string;
  defaultChecked: boolean;
  onRequestClose(): void;
}

export default function UserSettingsContent({
  dialogId,
  defaultChecked,
  onRequestClose,
}: UserSettingsContentProps): ReactElement {
  const saveId = `${dialogId}-save`;
  const cancelId = `${dialogId}-caancel`;
  const dispatch = useAppDispatch();
  const companies = useAppSelector(getUserCompanies);
  const mobile = useAppSelector(isMobile);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(defaultChecked);
  const [removingIds, setRemovingIds] = useState<EntityId[]>([]);
  const onRemoveToggle = useCallback(
    (companyId: EntityId, removing: boolean) => {
      setRemovingIds((prevRemovingIds) => {
        if (removing) {
          return prevRemovingIds.filter((id) => id !== companyId);
        }

        return [...prevRemovingIds, companyId];
      });
    },
    []
  );

  const save = useCallback(async () => {
    setLoading(true);
    await dispatch(
      patchCurrentUser({
        isTrackAnalyticsAllowed: checked,
        companies: companies.reduce<EntityId[]>((list, { id }) => {
          if (!removingIds.includes(id)) {
            list.push(id);
          }

          return list;
        }, []),
      })
    );
    setLoading(false);
    onRequestClose();
  }, [checked, companies, dispatch, onRequestClose, removingIds]);

  return (
    <>
      <DialogContent className={styles.content}>
        <UserAnalytics
          checked={checked}
          onChange={(checked) => setChecked(checked)}
        />
        <UserAccounts
          companies={companies}
          removingIds={removingIds}
          onRemoveToggle={onRemoveToggle}
        />
      </DialogContent>
      <DialogFooter className={styles.footer}>
        <DialogButton
          id={cancelId}
          onClick={onRequestClose}
          theme="stone"
          dense={!mobile}
        >
          <FormattedMessage messageId="Cancel" />
        </DialogButton>
        <DialogButton
          id={saveId}
          onClick={save}
          loading={loading}
          dense={!mobile}
        >
          <FormattedMessage messageId="Save" />
        </DialogButton>
      </DialogFooter>
    </>
  );
}
