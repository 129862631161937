import { createEntityAdapter } from '@reduxjs/toolkit';
import { schema } from 'normalizr';
import { ScheduleItem } from 'fwi-fe-types';

export const adapter = createEntityAdapter<ScheduleItem>({
  selectId: (item) => item.scheduleId,
});

/**
 * @see {@link NormalizedChannels} for more info around the `channelSchedules`
 * identifier
 */
export const CHANNEL_SCHEDULE_ENTITY = new schema.Entity<ScheduleItem>(
  'channelSchedules',
  {},
  {
    idAttribute: 'scheduleId',
  }
);
export const CHANNEL_SCHEDULES_SCHEMA = new schema.Array(
  CHANNEL_SCHEDULE_ENTITY
);
