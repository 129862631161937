import { createSelector } from 'reselect';
import { getLocation } from 'connected-react-router';
import { pick } from 'lodash';
import { SortOrder } from 'fwi-fe-types';
import {
  ADMIN_CENTER_USERS_COLUMNS,
  ADMIN_CENTER_GROUPS_COLUMNS,
  ADMIN_CENTER_LABELS_COLUMNS,
  CUSTOMER_MGMT_COLUMNS,
} from 'fwi-fe-components';

import {
  getCustomersSort,
  getNonDefaultLabels,
  getUserEntities,
  getGroupEntities,
} from 'appState';
import { FILTER_KEYS } from 'constants/pagination';
import {
  isAdminGroups,
  isAdminLabels,
  isAdminUsers,
  isCustomers,
} from 'utils/routes';

const USER_KEYS = [
  'id',
  'firstName',
  'lastName',
  'modifiedOn',
  'licenseType',
  'lastLoginOn',
];
const GROUP_KEYS = ['id', 'name', 'users', 'modifiedOn'];

const getHomeUsersList = (state) => state.pagination.homeUsers.itemList;

const getHomeGroupsList = (state) => state.pagination.homeGroups.itemList;
const getPathname = (state) => getLocation(state).pathname;

/**
 * A selector for getting a list of users for the home screen
 *
 * @param {Object} state - the top-level store's state
 * @return the list of users
 */
export const makeHomeUsersSelector = () =>
  createSelector(getHomeUsersList, getUserEntities, (ids, users) =>
    ids.reduce((list, id) => {
      const user = pick(users[id], USER_KEYS);
      list.push(user);
      return list;
    }, [])
  );

export const makeIsEmptyHomeUsersSelector = () =>
  createSelector(getHomeUsersList, (ids) => !ids || !ids.length);

/**
 * A selector for getting a list of groups for the home screen
 *
 * @param {Object} state - the top-level store's state
 * @return {Array.<Object>} the list of groups
 */
export const makeHomeGroupsSelector = () =>
  createSelector(getHomeGroupsList, getGroupEntities, (ids, groups) =>
    ids.reduce((list, id) => {
      const group = pick(groups[id], GROUP_KEYS);
      list.push(group);
      return list;
    }, [])
  );

export const makeIsEmptyHomeGroupsSelector = () =>
  createSelector(getHomeGroupsList, (ids) => !ids || !ids.length);

/**
 * Gets all necessary data for displaying users, groups, or labels
 * on the home screen table.
 *
 * @param {Object} state - the top-level store's state
 * @param {String} type - the type of items to retrieve
 */
export const getHomeLibraryData = (state, type) => {
  let librarySelector;
  let homePagination;
  let filters = {};
  if (type === 'users') {
    librarySelector = makeHomeUsersSelector();
    homePagination = state.pagination.homeUsers;
    filters = pick(homePagination, FILTER_KEYS);
  } else if (type === 'groups') {
    librarySelector = makeHomeGroupsSelector();
    homePagination = state.pagination.homeGroups;
  } else if (type === 'labels') {
    librarySelector = getNonDefaultLabels;
    homePagination = state.pagination.homeLabels;
  }

  const { loading, numberOfItems, sort } = homePagination;
  return {
    filters,
    libraryItems: librarySelector(state),
    totalNumberOfItems: numberOfItems,
    loading,
    isSorted: sort?.isSorted,
    sort: sort?.sort,
    sortOrder: sort?.sortOrder,
  };
};

/**
 * This is used to get the current columns for the `StickyTable`
 * and `SortViewToggle` in the list views.
 */
export const getCurrentColumns = (state) => {
  const pathname = getPathname(state);
  if (isCustomers(pathname)) {
    return CUSTOMER_MGMT_COLUMNS;
  }

  if (isAdminUsers(pathname)) {
    return ADMIN_CENTER_USERS_COLUMNS;
  }

  if (isAdminGroups(pathname)) {
    return ADMIN_CENTER_GROUPS_COLUMNS;
  }

  if (isAdminLabels(pathname)) {
    return ADMIN_CENTER_LABELS_COLUMNS;
  }

  return [];
};

/**
 * Gets the current sort behavior used for the `StickyTable` and
 * `SortViewToggle` in the list views.
 */
export const getCurrentSort = (state) => {
  const pathname = getPathname(state);
  if (isCustomers(pathname)) {
    return getCustomersSort(state);
  }

  let adminStateKey;
  if (isAdminUsers(pathname)) {
    adminStateKey = 'homeUsers';
  } else if (isAdminGroups(pathname)) {
    adminStateKey = 'homeGroups';
  } else if (isAdminLabels(pathname)) {
    adminStateKey = 'homeLabels';
  }

  if (adminStateKey) {
    const { sort, sortOrder } = state.pagination[adminStateKey].sort;
    return { sort, sortOrder };
  }

  return { sort: '', sortOrder: SortOrder.DESCENDING };
};
