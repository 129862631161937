import { getIdToken, setCookie } from 'fwi-fe-utils';

import { removeSession } from 'appState/auth/api';
import { FWI_LOGOUT_ERROR, FWI_LOGOUT_FROM } from 'constants/cookies';
import { SINGLE_LOGOUT_ENDPOINT } from 'constants/endpoints';
import { removeTokenControllers } from 'utils/tokens';
import { removeAuthenticationCookies } from './auth';
import {
  getKnownQueryParams,
  isUnifiedDashboard,
  isValidRoute,
  LOGIN,
  LOGOUT_OKTA,
  SLO_REDIRECT_URI,
} from './routes';

function isValidPathname(from: string): boolean {
  return (
    isValidRoute(from) && !isUnifiedDashboard(from) && from !== LOGOUT_OKTA
  );
}

export function postLogoutUrl(from: string, error: string): string {
  const params = new URLSearchParams();
  if (isValidPathname(from)) {
    params.append('from', from);
  }

  if (error) {
    params.append('error_description', error);
  }

  const query = params.toString();
  const q = query.length ? `?${query}` : '';

  return `${LOGIN}${q}`;
}

/**
 * Logs the user out from the app and tries to allow the user to navigate back
 * to the current url once they log back in.
 *
 * This does a **hard** reload which means all state will be lost.
 *
 * @param errorDescription - An optional error description message to display
 * when the user logs out. This should really be one of the
 * {@link KNOWN_AUTH_ERRORS}
 */
export async function logout(errorDescription?: unknown): Promise<void> {
  const idToken = getIdToken();

  // must be called before removing cookies and has to be awaited or else the
  // browser will cancel the request
  await removeSession();
  removeAuthenticationCookies();
  removeTokenControllers();
  const { search, pathname } = window.location;
  const params = getKnownQueryParams(search);
  const { error } = params;
  let { from } = params;
  if (!from && isValidPathname(pathname)) {
    from = pathname;
  }

  const errorMessage =
    typeof errorDescription === 'string' ? errorDescription : error;

  // session somehow already expired? or not using okta?
  if (!idToken) {
    window.location.href = postLogoutUrl(from, errorMessage);
    return;
  }

  setCookie(FWI_LOGOUT_FROM, from);
  setCookie(FWI_LOGOUT_ERROR, errorMessage);

  // The okta request will fail if the idToken has been encoded
  const redirectUri = `post_logout_redirect_uri=${SLO_REDIRECT_URI}`;
  window.location.href = `${SINGLE_LOGOUT_ENDPOINT}?id_token_hint=${idToken}&${redirectUri}`;
}
