import {
  getRemainingSessionTime,
  ONE_MINUTE,
  SessionModal as SharedSessionModal,
  updateAccessTokenIfNeeded,
} from 'fwi-fe-components';
import { authTokenController, isPidsParamsDefined } from 'fwi-fe-utils';
import { ReactElement, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { isAuthenticated, useAppSelector } from 'appState';
import { useSplitInteger } from 'hooks/useSplit';
import { logout } from 'utils/logout';

export default function SessionModal(): ReactElement | null {
  const { search } = useLocation();
  const isPids = isPidsParamsDefined(search);
  const authenticated = useAppSelector((state) =>
    isAuthenticated(state, false)
  );
  const inactivityTime = useSplitInteger('DS_INACTIVITY_TIME', 30);
  const inactiveMilliseconds = inactivityTime * ONE_MINUTE;

  const getRemainingTime = useCallback(
    () => getRemainingSessionTime({ inactiveMilliseconds }),
    [inactiveMilliseconds]
  );
  const onExpired = useCallback(() => {
    logout('SessionExpired');
  }, []);
  const onActivity = useCallback(() => {
    updateAccessTokenIfNeeded({
      refreshAccessToken: authTokenController.refreshAccessToken,
    });
  }, []);

  if (!authenticated || isPids) {
    return null;
  }

  return (
    <SharedSessionModal
      onExpired={onExpired}
      onActivity={onActivity}
      extendSession={authTokenController.refreshAccessToken}
      getRemainingTime={getRemainingTime}
    />
  );
}
