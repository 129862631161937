import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { StatusCodes } from 'fwi-fe-utils';

import { APIRejectionThunkConfig, CompanySettings } from 'appTypes';
import { COMPANY_SETTINGS_ENDPOINT } from 'constants/endpoints';
import { api } from 'utils/api';

/**
 * Fetches the configurable settings for the current user's company.
 */
export const fetchCompanySettings = createAsyncThunk<
  CompanySettings,
  void,
  APIRejectionThunkConfig
>('companySettings/fetch', async (_, { rejectWithValue }) => {
  const response = await api(COMPANY_SETTINGS_ENDPOINT);

  if (!response.ok) {
    const { status } = response;
    return rejectWithValue({
      status,
      unmodified: status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json = await response.json();

  if (isEmpty(json)) {
    return rejectWithValue({
      status: StatusCodes.NOT_FOUND,
      unmodified: false,
    });
  }
  const responseData: CompanySettings = {
    publicUrl: json.publicUrl ?? true,
    embedDomains: json.embedDomains || [],
    feedsEnabled: json.feedsEnabled ?? false,
    playbackReportingEnabled: json.playbackReportingEnabled ?? {
      value: false,
      lastModified: '',
    },
    isReportsEnabledForTheFirstTime:
      json.metadataReportingEnabled?.value ?? false,
  };

  return responseData;
});

/**
 * Updates the configurable settings for the current user's company.
 *
 * @param settings The updated {@link CompanySettings}
 */
export const putCompanySettings = createAsyncThunk<
  CompanySettings,
  CompanySettings
>('companySettings/put', async (settings, { rejectWithValue }) => {
  const response = await api(COMPANY_SETTINGS_ENDPOINT, {
    method: 'PUT',
    body: JSON.stringify(settings),
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  const json = await response.json();

  if (isEmpty(json)) {
    return rejectWithValue({
      status: StatusCodes.NOT_FOUND,
      unmodified: false,
    });
  }
  return {
    publicUrl: json.settings.publicUrl ?? true,
    embedDomains: json.settings.embedDomains || [],
    feedsEnabled: json.settings.feedsEnabled ?? false,
    playbackReportingEnabled: json.settings.playbackReportingEnabled ?? {
      value: false,
      lastModified: '',
    },
    isReportsEnabledForTheFirstTime:
      json.settings.metadataReportingEnabled?.value ?? false,
  };
});
