import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { BulkUserOpsState, BulkUserUploads, UploadStatus } from 'appTypes';

import { postBulkUserUploadCsv } from './api';

const { COMPLETE, FAILED, UPLOADING } = UploadStatus;

const EMPTY_UPLOADS: BulkUserUploads = {};

export const INITIAL_BULK_USER_OPS_STATE: BulkUserOpsState = {
  visible: false,
  uploads: EMPTY_UPLOADS,
};

export const { actions, reducer } = createSlice({
  name: 'bulkUserOps',
  initialState: INITIAL_BULK_USER_OPS_STATE,
  reducers: {
    showBulkUploadModal: (state) => ({ ...state, visible: true }),
    hideBulkUploadModal: (state) => ({ ...state, visible: false }),
    clearAllUploads: (state) => ({ ...state, uploads: EMPTY_UPLOADS }),
    clearUploadError: (
      state: BulkUserOpsState,
      action: PayloadAction<string>
    ) => ({
      ...state,
      uploads: {
        ...state.uploads,
        [action.payload]: omit(state.uploads[action.payload], 'error'),
      },
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(postBulkUserUploadCsv.pending, (state, action) => {
        const fileName = action.meta.arg.name;
        const existing = state.uploads[fileName];
        if (!existing) {
          state.uploads[fileName] = { status: UPLOADING, fileName };
        }
      })
      .addCase(postBulkUserUploadCsv.fulfilled, (state, action) => {
        const fileName = action.meta.arg.name;
        state.uploads[fileName].status = COMPLETE;
      })
      .addCase(postBulkUserUploadCsv.rejected, (state, action) => {
        const fileName = action.meta.arg.name;
        const existing = state.uploads[fileName];
        const error = action.payload?.error;
        if (existing && error) {
          existing.status = FAILED;
          existing.error = error;
        }
      }),
});

export const {
  clearAllUploads,
  clearUploadError,
  hideBulkUploadModal,
  showBulkUploadModal,
} = actions;

export default reducer;
