import { createReducer } from '@reduxjs/toolkit';

import { ChannelInterruptsState } from 'appTypes';
import { fetchChannel, fetchChannels } from 'appState/channels/api';

import { adapter } from './schema';

export const INITIAL_CHANNEL_INTERRUPTS_STATE: ChannelInterruptsState =
  adapter.getInitialState();

export default createReducer(INITIAL_CHANNEL_INTERRUPTS_STATE, (builder) =>
  builder
    .addCase(fetchChannels.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.channelInterrupts);
    })
    .addCase(fetchChannel.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.channelInterrupts);
    })
);
