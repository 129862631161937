import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectionsState } from 'appTypes';
import { EntityId } from 'fwi-fe-types';

export const INITIAL_SELECTIONS_STATE: SelectionsState = {
  entities: [],
};

const { actions, reducer } = createSlice({
  name: 'selections',
  initialState: INITIAL_SELECTIONS_STATE,
  reducers: {
    /**
     * Conditionally adds the new entity id to the end of the list of selected
     * ids if it has not already been added to prevent duplicates.
     */
    addSelection: (state, action: PayloadAction<EntityId>) => {
      state.entities = [...new Set([...state.entities, action.payload])];
    },

    /**
     * Adds any new entity ids to the end of the list of selected ids preventing
     * duplicates.
     */
    addSelections: (state, action: PayloadAction<readonly EntityId[]>) => {
      state.entities = [...new Set([...state.entities, ...action.payload])];
    },

    /**
     * This is used for toggling the selection state when only one entity can be
     * selected at a time.
     *
     * If the list of selected entity ids has length of 1 and includes the
     * provided entity id, all entities will be deselected. Otherwise the list
     * of selected entity ids will only contain the new entity id.
     */
    toggleSelection: (state, action: PayloadAction<EntityId>) => {
      if (
        state.entities.length === 1 &&
        state.entities.includes(action.payload)
      ) {
        state.entities = [];
      } else {
        state.entities = [action.payload];
      }
    },

    /**
     * This is used for toggling the selection state when multiple entities can
     * be selected at the same time.
     *
     * If the list of selected entity ids includes the provided entity id, the
     * entity id will be removed from the list. Otherwise the list will add the
     * entity id.
     */
    toggleMultiSelection: (state, action: PayloadAction<EntityId>) => {
      const index = state.entities.indexOf(action.payload);
      if (index !== -1) {
        state.entities.splice(index, 1);
      } else {
        state.entities.push(action.payload);
      }
    },

    /**
     * Removes a single entity id from the list of selected entity ids.
     */
    removeSelection: (state, action: PayloadAction<EntityId>) => {
      state.entities = state.entities.filter((id) => id !== action.payload);
    },
    removeAllSelections: (state) => {
      state.entities = [];
    },
  },
});

export const {
  addSelection,
  addSelections,
  toggleSelection,
  toggleMultiSelection,
  removeSelection,
  removeAllSelections,
} = actions;

export default reducer;
