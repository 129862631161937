import { HTMLAttributes, ReactElement, ReactNode } from 'react';
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogCloseButton,
} from 'fwi-fe-components';
import cn from 'classnames';

import { isMobile, useAppSelector } from 'appState';

import styles from './RightFixedDialog.module.scss';

const classNames = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive,
};

export interface RightFixedDialogProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'role'> {
  id: string;
  visible: boolean;
  onRequestClose(): void;
  title: ReactNode;
  children: ReactNode;
}

export default function RightFixedDialog({
  className,
  children,
  title,
  ...props
}: RightFixedDialogProps): ReactElement {
  const { id, onRequestClose } = props;
  const titleId = `${id}-title`;
  const closeId = `${id}-x`;
  const mobile = useAppSelector(isMobile);

  return (
    <Dialog
      {...props}
      aria-labelledby={titleId}
      type="custom"
      className={cn(
        styles.container,
        {
          [styles.containerDesktop]: !mobile,
        },
        className
      )}
      classNames={classNames}
    >
      <DialogHeader
        className={cn(styles.header, {
          [styles.headerDesktop]: !mobile,
        })}
      >
        <DialogTitle id={titleId} className={styles.title}>
          {title}
        </DialogTitle>
        <DialogCloseButton
          id={closeId}
          onClick={onRequestClose}
          isOnDarkBackground={false}
        />
      </DialogHeader>
      {children}
    </Dialog>
  );
}
