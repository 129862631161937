import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfoModalState } from 'appTypes';

export const INITIAL_INFO_MODAL_STATE: InfoModalState = {
  visible: false,
  titleId: '',
  content: { id: '', values: {} },
};

type ShowInfoModalAction = PayloadAction<Omit<InfoModalState, 'visible'>>;

const { actions, reducer } = createSlice({
  name: 'info',
  initialState: INITIAL_INFO_MODAL_STATE,
  reducers: {
    showInfoModal: (_, action: ShowInfoModalAction) => {
      const { titleId, content } = action.payload;
      return {
        visible: true,
        titleId,
        content,
      };
    },
    hideInfoModal: (state) => ({ ...state, visible: false }),
  },
});

export const { showInfoModal, hideInfoModal } = actions;
export default reducer;
