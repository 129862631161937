import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit, pick } from 'lodash';

import { StatusCodes, APIConstants } from 'fwi-fe-utils';
import {
  PaginationRequestOptions,
  EntityId,
  UserEntity,
  LicenseType,
} from 'fwi-fe-types';

import { addToast } from 'appState/toasts';
import {
  AppState,
  AppDispatch,
  SharedPaginationState,
  SharedSortable,
  FilterableUsers,
  UserFilters,
  APIRejectionThunkConfig,
  APIStatusRejectionThunkConfig,
  APIMessageRejectionThunkConfig,
  MessageJsonResponse,
  EntityAction,
} from 'appTypes';
import {
  USERS_SEARCH_ENDPOINT,
  USERS_ID_ENDPOINT,
  USERS_ENDPOINT,
  USERS_RESEND_ACTIVATION_LINK_ENDPOINT,
  USERS_SEND_PASSWORD_RESET_LINK_ENDPOINT,
  USERS_UNLOCK_ENDPOINT,
  GROUPS_USERS_ENDPOINT,
  USERS_EXPORT_ENDPOINT,
} from 'constants/endpoints';
import {
  HOME,
  MODAL,
  PaginationLocation,
  USERS_INITIAL_STATE as DEFAULT_STATE,
  FILTER_KEYS,
} from 'constants/pagination';
import { api, apiDownload } from 'utils/api';
import { invalidateTags } from './utils';

import { getUserById, isLoadingUser } from './selectors';

const { POST, PUT, DELETE } = APIConstants;

export interface FetchUsersArgs
  extends Partial<PaginationRequestOptions>,
    Partial<FilterableUsers> {
  paginationState:
    | SharedPaginationState
    | (SharedPaginationState & SharedSortable);
  paginationLocation: PaginationLocation;
}

export interface UsersResponseData {
  items: UserEntity[];
  numberOfItems: number;
}

export interface FullUsersResponse
  extends Partial<FetchUsersArgs>,
    UsersResponseData {}

export const getUsersFetchOptions = (
  paginationState?:
    | SharedPaginationState
    | (SharedPaginationState & SharedSortable),
  fetchOptions?: FetchUsersArgs
): Partial<PaginationRequestOptions & UserFilters> => {
  const cleanedOptions = {
    ...omit(fetchOptions, ['paginationState', 'paginationLocation']),
  };
  const { itemList, sort: stateCombinedSort } = {
    ...DEFAULT_STATE,
    ...paginationState,
  };
  const { sort: stateSort, sortOrder: stateSortOrder } = stateCombinedSort;

  const stateOptions = {
    offset: itemList.length,
    sort: stateSort,
    sortOrder: stateSortOrder,
    size: 22,
  };

  if (!fetchOptions) {
    return stateOptions;
  }

  return {
    ...pick(paginationState, FILTER_KEYS),
    ...stateOptions,
    ...cleanedOptions,
  };
};

export const fetchUsers = createAsyncThunk<
  FullUsersResponse,
  FetchUsersArgs,
  APIStatusRejectionThunkConfig
>('users/post', async (options, { rejectWithValue }) => {
  const { paginationState } = options;
  const fetchOptions = getUsersFetchOptions(paginationState, options);
  const { licenseTypes, lastAccessTypes, groups, ...nonFilterOptions } =
    fetchOptions;
  const filter = {
    licenseTypes: licenseTypes || [],
    groups: groups || [],
    lastAccessTypes: lastAccessTypes || [],
  };
  const { sort, ...nonSortOptions } = nonFilterOptions;

  const response = await api(USERS_SEARCH_ENDPOINT, {
    method: POST,
    body: JSON.stringify({ ...nonSortOptions, sortBy: sort, filter }),
  });
  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  const json: UsersResponseData = await response.json();
  const fullResponse: FullUsersResponse = {
    ...fetchOptions,
    ...json,
  };

  return fullResponse;
});

export function fetchHomeUsers(
  options?: Partial<PaginationRequestOptions> & Partial<FilterableUsers>
) {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const { homeUsers } = getState().pagination;
    return dispatch(
      fetchUsers({
        ...options,
        paginationState: homeUsers,
        paginationLocation: HOME,
      })
    );
  };
}

export function fetchModalUsers(options?: Partial<PaginationRequestOptions>) {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const { groupModalUsers } = getState().pagination;
    return dispatch(
      fetchUsers({
        ...options,
        paginationState: groupModalUsers,
        paginationLocation: MODAL,
      })
    );
  };
}

/**
 * Fetches a user by id
 *
 * @param arg - The user id to fetch
 * @returns a promise to the {@link UserEntity}
 */
export const fetchUser = createAsyncThunk<
  UserEntity,
  EntityId,
  APIRejectionThunkConfig
>(
  'user/get',
  async (userId, { rejectWithValue }) => {
    const response = await api(USERS_ID_ENDPOINT, {
      params: { userId },
    });

    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    const json: UserEntity = await response.json();
    return json;
  },
  {
    condition(arg, { getState }) {
      return arg !== EntityAction.New && !isLoadingUser(getState(), arg);
    },
  }
);

export interface PostUserArgs {
  user: Partial<UserEntity>;
  disableRedirect?: boolean;
}

export const postUser = createAsyncThunk<
  UserEntity,
  PostUserArgs,
  APIMessageRejectionThunkConfig
>('user/post', async ({ user }, { dispatch, rejectWithValue }) => {
  const response = await api(USERS_ENDPOINT, {
    method: POST,
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    const json = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }

  invalidateTags({ dispatch, tags: ['Users'] });
  const json: UserEntity = await response.json();
  return json;
});

export interface PutUserArgs {
  userId: EntityId;
  user: Partial<UserEntity>;
  disableRedirect?: boolean;
}

export const putUser = createAsyncThunk<
  UserEntity,
  PutUserArgs,
  APIMessageRejectionThunkConfig
>('user/put', async ({ userId, user }, { dispatch, rejectWithValue }) => {
  const response = await api(USERS_ID_ENDPOINT, {
    method: PUT,
    body: JSON.stringify(user),
    params: { userId },
  });

  if (!response.ok) {
    const json = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }

  invalidateTags({ dispatch, tags: ['Users'] });
  return await response.json();
});

export interface DeleteUserArgs {
  userId: EntityId;
  disableRedirect?: boolean;
}

export const deleteUser = createAsyncThunk<
  { licenseType: LicenseType; isInternal: boolean },
  DeleteUserArgs,
  APIMessageRejectionThunkConfig
>(
  'user/delete',
  async ({ userId }, { dispatch, getState, rejectWithValue }) => {
    const user = getUserById(getState(), userId);
    // this shouldn't really be possible
    if (!user) {
      return rejectWithValue({
        status: 404,
        statusText: 'Not Found',
        message: 'User does not exist',
      });
    }

    const { licenseType, isInternal } = user;
    const response = await api(USERS_ID_ENDPOINT, {
      method: DELETE,
      params: { userId },
    });

    if (!response.ok) {
      const json: MessageJsonResponse = await response.json();
      const { status, statusText } = response;
      return rejectWithValue({ status, statusText, message: json.message });
    }

    invalidateTags({ dispatch, tags: ['Users'] });
    return { licenseType, isInternal };
  }
);

/**
 * Triggers an email for the user to activate their account which can be used if
 * the user waited too long before creating their password and logging in for
 * the first time.
 *
 * @param userId - The user id to send an email for.
 */
export const resendActivationLink = createAsyncThunk<
  void,
  EntityId,
  APIMessageRejectionThunkConfig
>('user/resendActivationLink', async (userId, { rejectWithValue }) => {
  const response = await api(USERS_RESEND_ACTIVATION_LINK_ENDPOINT, {
    method: POST,
    body: JSON.stringify({}),
    params: { userId },
  });

  if (!response.ok) {
    const json = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }
});

/**
 * Triggers an email for the user to reset their password.
 *
 * @param userId - The user id to send an email for.
 */
export const sendPasswordResetLink = createAsyncThunk<
  void,
  EntityId,
  APIMessageRejectionThunkConfig
>('user/sendPasswordResetLink', async (userId, { rejectWithValue }) => {
  const response = await api(USERS_SEND_PASSWORD_RESET_LINK_ENDPOINT, {
    method: POST,
    body: JSON.stringify({}),
    params: { userId },
  });

  if (!response.ok) {
    const json = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }
});

/**
 * Unlocks a user if they failed logging in multiple times.
 *
 * @param userId - The user id to send an email for.
 */
export const unlockUserAccount = createAsyncThunk<
  void,
  EntityId,
  APIMessageRejectionThunkConfig
>('user/unlockAccount', async (userId, { rejectWithValue }) => {
  const response = await api(USERS_UNLOCK_ENDPOINT, {
    method: POST,
    body: JSON.stringify({}),
    params: { userId },
  });

  if (!response.ok) {
    const json: MessageJsonResponse = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }
});

/**
 * Fetches a list of all the users associated with a group.
 *
 * @param arg - The group id
 * @returns a list of associated {@link UserEntity}
 */
export const fetchUsersByGroupId = createAsyncThunk<
  UserEntity[],
  EntityId,
  APIRejectionThunkConfig
>(
  'users/fetchByGroupId',
  async (groupId, { rejectWithValue }) => {
    const response = await api(GROUPS_USERS_ENDPOINT, {
      params: { groupId },
    });

    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    const json: UserEntity[] = await response.json();
    return json;
  },
  {
    condition(arg) {
      return arg !== EntityAction.New;
    },
  }
);

/**
 * Downloads a Users Report
 *
 * @param fileName - Name of downloaded csv file
 */
export const exportUsersReport = createAsyncThunk<void, string>(
  'users/exports',
  async (fileName, { dispatch }) => {
    await apiDownload(USERS_EXPORT_ENDPOINT, fileName).catch(() =>
      dispatch(addToast({ messageId: 'UsersReportDownloadFailure' }))
    );
  }
);
