import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchButton } from 'fwi-fe-components';

import { isMobile, useAppSelector } from 'appState';
import { HEADER_BUTTON } from 'constants/branding';
import { isAdminLabels } from 'utils/routes';

export interface AdminSearchButtonProps {
  id: string;
}

export default function AdminSearchButton({
  id,
}: Readonly<AdminSearchButtonProps>): ReactElement | null {
  const { pathname } = useLocation();
  const mobile = useAppSelector(isMobile);

  if (!isAdminLabels(pathname)) {
    return null;
  }

  return <SearchButton id={id} mobile={mobile} className={HEADER_BUTTON} />;
}
