import { createEntityAdapter } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { NormalizedLabelEntity, NormalizedLabels } from 'appTypes';
import { LABEL_VALUES_SCHEMA } from 'appState/labelValues/schema';

export const adapter = createEntityAdapter<NormalizedLabelEntity>();

/**
 * @see {@link NormalizedLabels} for more info around the `labels` identifier
 */
export const LABEL_ENTITY = new schema.Entity<NormalizedLabelEntity>(
  'labels',
  {
    values: LABEL_VALUES_SCHEMA,
  },
  {
    processStrategy({ entities, ...label }) {
      // TODO: Determine how this will look once BE has been updated to provide devices.
      // entities?.devices will only exist after fetching a single device? and assuming
      // devices will be returned when fetching all devices
      const devices = entities?.devices ?? label.devices;
      return {
        ...label,
        devices,
      };
    },
  }
);
export const LABELS_SCHEMA = new schema.Array<NormalizedLabelEntity[]>(
  LABEL_ENTITY
);

/**
 * Transforms and normalizes labels from a list of json objects.
 *
 * @param json - A list of json objects (normally from the BE) that should be
 * normalized
 * @returns the {@link NormalizedLabels} record
 */
export function normalizeLabels(json: readonly unknown[]): NormalizedLabels {
  const { labels = {}, labelValues = {} } = normalize<
    unknown,
    Partial<NormalizedLabels>
  >(json, LABELS_SCHEMA).entities;

  return { labels, labelValues };
}

/**
 * Transforms and normalizes a label from a json object.
 *
 * @param json - A json object (normally from the BE) that should be normalized
 * @returns the {@link NormalizedLabels} record
 */
export function normalizeLabel(json: unknown): NormalizedLabels {
  return normalizeLabels([json]);
}
