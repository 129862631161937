import { APIRejection, StatusCodes } from 'fwi-fe-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppState, ChannelShareUrlQuota } from 'appTypes';
import { CHANNELS_EMBED_QUOTA_ENDPOINT } from 'constants/endpoints';
import { api } from 'utils/api';

import { isLoadingChannelShareQuota } from './selectors';

/**
 * Fetches the iFrame Embed/Url Embed/Channel Share Url quota.
 */
export const fetchChannelShareUrlQuota = createAsyncThunk<
  ChannelShareUrlQuota,
  void,
  { rejectValue: APIRejection; state: AppState }
>(
  'channels/fetchShareUrlQuota',
  async (_, { rejectWithValue }) => {
    const response = await api(CHANNELS_EMBED_QUOTA_ENDPOINT);
    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }
    const json: ChannelShareUrlQuota = await response.json();
    return json;
  },
  {
    condition(_, { getState }) {
      return !isLoadingChannelShareQuota(getState());
    },
  }
);
