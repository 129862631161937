import { FWI_AUTH_FROM, FWI_COMPANY_ID, setCookie } from 'fwi-fe-utils';
import { ReactElement, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { signInWithPids } from 'appState';
import {
  getKnownQueryParams,
  HOME,
  isLogin,
  isUnifiedDashboard,
  LOGIN,
} from 'utils/routes';

export function PidsOrLoginRedirect(): ReactElement | null {
  const { search, pathname } = useLocation();
  const {
    pids,
    companyId,
    from: existingFromUrl,
  } = getKnownQueryParams(search);

  let from = existingFromUrl;
  if (
    !from &&
    !isLogin(pathname) &&
    !isUnifiedDashboard(pathname) &&
    pathname !== HOME
  ) {
    from = pathname;
  }

  useEffect(() => {
    if (!pids) {
      return;
    }

    setCookie(FWI_AUTH_FROM, from);
    setCookie(FWI_COMPANY_ID, companyId);
    signInWithPids({ companyId });
  }, [companyId, from, pids]);

  if (pids) {
    return null;
  }

  return (
    <Redirect
      to={{
        pathname: LOGIN,
        search: from ? `?from=${encodeURIComponent(from)}` : '',
      }}
    />
  );
}
