import { fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getLocation } from 'connected-react-router';

import {
  deleteGroup,
  deleteLabels,
  deleteUser,
  fetchGroups,
  fetchLabels,
} from 'appState';
import { updateAppSize } from 'appState/appSize';
import { HOME } from 'constants/pagination';
import { isAdminLabels } from 'utils/routes';

import { removeAllSelections } from './reducer';

export function* clearGroupSelectionsOnFetch() {
  yield takeLatest(
    [fetchGroups.fulfilled.type],
    function* execute(action: ReturnType<typeof fetchGroups.pending>) {
      const { paginationLocation, offset } = action.meta.arg;
      if (paginationLocation === HOME && offset === 0) {
        yield put(removeAllSelections());
      }
    }
  );
}

export function* clearLabelsSelectionsOnFetch() {
  yield takeLatest([fetchLabels.fulfilled.type], function* execute() {
    yield put(removeAllSelections());
  });
}

export function* clearSelectionsOnItemDelete() {
  yield takeLatest(
    [
      deleteUser.pending.type,
      deleteGroup.pending.type,
      deleteLabels.pending.type,
    ],
    function* execute() {
      yield put(removeAllSelections());
    }
  );
}

export function* clearSelectionsOnMobile() {
  yield takeEvery(
    updateAppSize,
    function* handle(action: ReturnType<typeof updateAppSize>) {
      if (!action.payload.mobile) {
        return;
      }

      const pathname: string = yield select(
        (state) => getLocation(state).pathname
      );

      if (!isAdminLabels(pathname)) {
        yield put(removeAllSelections());
      }
    }
  );
}

export function* selectionsSagas() {
  yield fork(clearGroupSelectionsOnFetch);
  yield fork(clearLabelsSelectionsOnFetch);
  yield fork(clearSelectionsOnItemDelete);
  yield fork(clearSelectionsOnMobile);
}
