import Joi from 'joi';
import { CONSTRAINTS } from 'fwi-constants';

import {
  USER_DESC_LIMIT,
  USER_EMAIL_LIMIT,
  USER_FIRST_NAME_LIMIT,
  USER_LAST_NAME_LIMIT,
} from 'constants/constraints';
import { buildErrorRecord, ErrorRecord } from './buildErrorRecord';
import { UserEntity } from 'fwi-fe-types';

const { EMAIL_REGEX } = CONSTRAINTS;

const USER_SCHEMA = Joi.object({
  firstName: Joi.string().max(USER_FIRST_NAME_LIMIT).required(),
  lastName: Joi.string().max(USER_LAST_NAME_LIMIT).required(),
  email: Joi.string().regex(EMAIL_REGEX).max(USER_EMAIL_LIMIT).required(),
  description: Joi.string().max(USER_DESC_LIMIT).optional().allow(''),
});

export function validateUser(user: Partial<UserEntity>): ErrorRecord {
  return buildErrorRecord(user, USER_SCHEMA);
}
