/**
 * This is one of the development, staging, or prod environment names used
 * for feature toggles.
 *
 * Examples:
 * - `admin`
 * - `contributor`
 * - `network`
 * - `cloudtest1`
 * - `cloudtest2`
 * - `staging`
 * - `prod-ap`
 * - `prod-eu`
 * - `prod-us`
 */
export const REACT_APP_ENV = process.env.REACT_APP_ENV;

/**
 * This will either be `development` or `production` and is used for the feature
 * toggles. This will be `development` unless the `REACT_APP_ENV` is one of:
 * - `prod-us`
 * - `prod-ap`
 * - `prod-eu`
 */
export const REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV;

/**
 * This is the base url used for all API endpoints throughout the app. Check out
 * `src/constants/endpoints.ts` for their usage.
 */
export const REACT_APP_BE_ORIGIN = process.env.REACT_APP_BE_ORIGIN;

/**
 * This is the base url used for the client. This can pretty much be ignored in
 * local development.
 */
export const REACT_APP_FE_ORIGIN = process.env.REACT_APP_FE_ORIGIN;

/**
 * The base okta url used for the auth flow.
 */
export const REACT_APP_OKTA_BASE_URL = process.env.REACT_APP_OKTA_BASE_URL;

/**
 * The okta client id used for the auth flow.
 */
export const REACT_APP_OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;

/**
 * The okta issuer url used for the auth flow.
 */
export const REACT_APP_OKTA_ISSUER_URL = process.env.REACT_APP_OKTA_ISSUER_URL;

export const REACT_APP_PIDS_IDP = process.env.REACT_APP_PIDS_IDP;

/**
 * The current AWS bucket name the app is using.
 */
export const REACT_APP_AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME;

/**
 * The current AWS region the app is using.
 */
export const REACT_APP_AWS_BUCKET_REGION =
  process.env.REACT_APP_AWS_BUCKET_REGION;

/**
 * An optional dev flag that disables segment analytics. You can also just unset
 * the `REACT_APP_SEGMENT_CODE` or disable analytics in the user settings panel.
 */
export const REACT_APP_DISABLE_SEGMENT =
  process.env.REACT_APP_DISABLE_SEGMENT === 'true';

/**
 * The segment code for the environment. This is required for non-local builds.
 */
export const REACT_APP_SEGMENT_CODE = process.env.REACT_APP_SEGMENT_CODE;

export const REACT_APP_AWS_IOT_HOST = process.env.REACT_APP_AWS_IOT_HOST;

/**
 * The split.io authorization key to use for the browser client
 */
export const REACT_APP_SPLIT_KEY = process.env.REACT_APP_SPLIT_KEY;

export const OIDC_ENDPOINT = process.env.REACT_APP_OIDC_ENDPOINT;
export const OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID;
export const HARMONY_ORIGIN = process.env.REACT_APP_HARMONY_ORIGIN;
export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
export const REPORTS_IFRAME_BASE_URL =
  process.env.REACT_APP_REPORTS_IFRAME_BASE_URL;
export const EMAIL_AND_FEEDS_AUTHENTICATE_ENDPOINT =
  process.env.REACT_APP_EMAIL_AND_FEEDS_AUTHENTICATE_ENDPOINT;

/** This should only have a value in production environments. */
export const EMAIL_AND_FEEDS_US_AUTHENTICATE_ENDPOINT =
  process.env.REACT_APP_EMAIL_AND_FEEDS_US_AUTHENTICATE_ENDPOINT;
/** This should only have a value in production environments. */
export const EMAIL_AND_FEEDS_AP_AUTHENTICATE_ENDPOINT =
  process.env.REACT_APP_EMAIL_AND_FEEDS_AP_AUTHENTICATE_ENDPOINT;
/** This should only have a value in production environments. */
export const EMAIL_AND_FEEDS_EU_AUTHENTICATE_ENDPOINT =
  process.env.REACT_APP_EMAIL_AND_FEEDS_EU_AUTHENTICATE_ENDPOINT;
