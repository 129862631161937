import { Sorting } from 'fwi-fe-utils';
import { ValuesOf, SortState } from 'fwi-fe-types';

import { PaginationState } from 'appTypes';

export const HOME = 'HOME';
export const MODAL = 'MODAL';
export const FILTER = 'FILTER';

export const PaginationLocation = {
  [HOME]: HOME,
  [MODAL]: MODAL,
  [FILTER]: FILTER,
} as const;
export type PaginationLocation = ValuesOf<typeof PaginationLocation>;

// The number doesn't matter too much here
export const DEFAULT_RETRIEVAL_SIZE = 22;

export const SORT_MODIFIED_ON = 'modifiedOn';
export const SORT_NAME = 'name';
export const SORT_LAST_ACCESS = 'lastAccessDate';
export const SORT_GROUPS = 'groups';
export const SORT_STATUS = 'status';
export const SORT_LICENSE = 'licenseType';
export const SORT_USERS = 'users';

export const INITIAL_OFFSET_STATE = 0;
export const INITIAL_LOADING_STATE = false;
export const INITIAL_SORT_STATE: SortState = {
  sort: 'name',
  sortOrder: Sorting.SORT_ASC,
  isSorted: false,
};

export const FILTER_KEYS = [
  'licenseTypes',
  'lastAccessTypes',
  'groups',
  'search',
];

export const USERS_INITIAL_STATE: PaginationState['homeUsers'] = {
  offset: INITIAL_OFFSET_STATE,
  loading: INITIAL_LOADING_STATE,
  numberOfItems: 0,
  itemList: [],
  sort: INITIAL_SORT_STATE,
  search: '',
  licenseTypes: [],
  lastAccessTypes: [],
  groups: [],
};

export const GROUPS_INITIAL_STATE: PaginationState['filterGroups'] = {
  offset: INITIAL_OFFSET_STATE,
  loading: INITIAL_LOADING_STATE,
  numberOfItems: 0,
  itemList: [],
  sort: INITIAL_SORT_STATE,
};
