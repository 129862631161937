// i didn't want to write type definitions for this, so keeping it in JS
// Also disabling eslint since the code is copy/pasted from segment
/* eslint-disable */

export const SEGMENT_SCRIPT_ID = 'segment-script';
export const SEGMENT_LOADED_EVENT = 'segmentLoaded';

const analytics = (() => {
  const analytics = window.analytics || {};
  if (!analytics.initialize)
    if (analytics.invoked) {
      // eslint-disable-next-line no-console
      if (window.console && console.error) {
        // eslint-disable-next-line no-console
        console.error('Segment snippet included twice.');
      }
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
      ];
      analytics.factory = (t, ...rest) => {
        return () => {
          const e = Array.prototype.slice.call(rest);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (let t = 0; t < analytics.methods.length; t += 1) {
        const e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = (t) => {
        document.addEventListener('segmentLoaded', () => {});

        const e = document.createElement('script');
        e.setAttribute('id', SEGMENT_SCRIPT_ID);
        e.type = 'text/javascript';
        e.async = !0;
        e.src = `${
          document.location.protocol === 'https:' ? 'https://' : 'http://'
        }cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
        const n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(e, n);

        // this is to add a global event so we can fire this event only after our Segment script has been
        // loaded and the related functions are available.
        e.addEventListener(
          'load',
          () => {
            document.dispatchEvent(new Event(SEGMENT_LOADED_EVENT));
          },
          false
        );
      };
      analytics.SNIPPET_VERSION = '4.0.0';
    }
  return analytics;
})();

export default analytics;
