import { LOCATION_CHANGE } from 'connected-react-router';
import { AnyAction, Middleware } from 'redux';

import { SYS_NAME_KEYS } from 'fwi-constants';
import { CurrentUserEntity, EntityId, UserCompany } from 'fwi-fe-types';
import { authTokenController, getCompanyId } from 'fwi-fe-utils';

import {
  getCurrentUser,
  getUserCompanies,
  isAuthenticated,
} from 'appState/auth/selectors';
import { AppState, SegmentAnalytics, UserTraits } from 'appTypes';
import { analyticsTrackEvent, identifyCustomer, identifyUser } from './actions';

declare global {
  interface Window {
    analytics?: SegmentAnalytics;
  }
}

export interface OptionalInfo {
  company?: {
    id: EntityId;
    name: string;
  };
}

function identify(
  { permissions, email, licenseType, firstName, lastName }: UserTraits,
  optionalInfo?: OptionalInfo
): void {
  if (!window.analytics) {
    return;
  }

  const entityId = authTokenController.getDecodedAccessToken()?.entityId ?? '';
  const isAdmin = !!permissions?.find(
    (perm) =>
      perm.moduleId === SYS_NAME_KEYS.AdminCenter && perm.effect === 'allow'
  );

  const groupId = optionalInfo?.company
    ? optionalInfo.company.id
    : getCompanyId();

  window.analytics.identify(entityId, {
    groupId,
    isAdmin,
    isInternal: /(fwi|fourwindsinteractive|poppulo)\.com$/.test(
      email.toLowerCase()
    ),
    licenseType,
    email,
    firstName,
    lastName,
    ...optionalInfo,
  });
}

export const analyticsMiddleware: Middleware =
  (store) => (next) => (action: AnyAction) => {
    if (
      typeof window.analytics === 'undefined' ||
      typeof action === 'function'
    ) {
      return next(action);
    }

    const state = store.getState();
    const user: CurrentUserEntity | undefined = getCurrentUser(state);
    const company = getUserCompanies(state).find(
      ({ id }) => id === user?.companyId
    );
    const optionalInfo: OptionalInfo = {};

    if (company) {
      const { id, name } = company;

      optionalInfo.company = {
        id,
        name,
      };
    }

    const disableAnalyticsCheck =
      action.payload?.properties?.disableAnalyticsCheck;
    if (!user?.isTrackAnalyticsAllowed || disableAnalyticsCheck) {
      return next(action);
    }

    handleAnalyticsEvent({
      state,
      action,
      user,
      company,
      optionalInfo,
    });

    return next(action);
  };

function handleAnalyticsEvent({
  state,
  action,
  user,
  optionalInfo,
  company,
}: {
  state: AppState;
  action: AnyAction;
  user: CurrentUserEntity;
  optionalInfo: OptionalInfo;
  company?: Readonly<UserCompany>;
}): void {
  if (!window.analytics) {
    return;
  }

  if (analyticsTrackEvent.match(action)) {
    window.analytics.track(action.payload.eventName, {
      ...action.payload.properties,
      groupId: company?.id || getCompanyId(),
    });
  } else if (identifyCustomer.match(action)) {
    window.analytics.group(company?.id || getCompanyId(), {
      salesforceAccountNumber: action.payload,
    });
  } else if (identifyUser.match(action)) {
    identify(action.payload, optionalInfo);
  } else if (action.type === LOCATION_CHANGE) {
    const { pathname } = action.payload.location;
    window.analytics.page(pathname, { path: pathname });
    if (isAuthenticated(state, false) && user.licenseType && user.email) {
      identify(user, optionalInfo);
    }
  }
}
