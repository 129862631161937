import { StatusCodes } from 'fwi-fe-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIRejectionThunkConfig, MarketSolutionEntity } from 'appTypes';
import { MARKET_SOLUTIONS_ENDPOINT } from 'constants/endpoints';
import { api } from 'utils/api';

/**
 * Fetches the available market solutions
 *
 * @returns An array of {@link MarketSolutionEntity}
 */
export const fetchMarketSolutions = createAsyncThunk<
  MarketSolutionEntity[],
  void,
  APIRejectionThunkConfig
>('marketSolutions/fetch', async (_, { rejectWithValue }) => {
  const response = await api(MARKET_SOLUTIONS_ENDPOINT);

  if (!response.ok) {
    return rejectWithValue({
      status: response.status,
      unmodified: response.status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json: MarketSolutionEntity[] = await response.json();
  return json;
});
