import { ReactElement } from 'react';
import { UserCompany, EntityId } from 'fwi-fe-types';

import { ReadonlyEntityList } from 'appTypes';
import FormattedMessage from 'components/FormattedMessage';

import styles from './UserAccounts.module.scss';
import UserAccount from './UserAccount';

export interface UserAccountsProps {
  companies: ReadonlyEntityList<UserCompany>;
  removingIds: readonly EntityId[];
  onRemoveToggle(companyId: EntityId, removing: boolean): void;
}

export default function UserAccounts({
  companies,
  removingIds,
  onRemoveToggle,
}: UserAccountsProps): ReactElement {
  return (
    <ul className={styles.list}>
      <FormattedMessage
        messageId="Accounts"
        component="li"
        className={styles.heading}
      />
      {companies.map(({ id, name }, i) => {
        const removing = removingIds.includes(id);
        return (
          <UserAccount
            key={id}
            id={`user-settings-account-${i + 1}`}
            companyId={id}
            companyName={name}
            removing={removing}
            removable={removingIds.length < companies.length - 1 || removing}
            onRemoveToggle={onRemoveToggle}
          />
        );
      })}
    </ul>
  );
}
