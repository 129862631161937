import { createReducer } from '@reduxjs/toolkit';
import { GroupEntity } from 'fwi-fe-types';

import { SharedPaginationState } from 'appTypes';
import {
  INITIAL_OFFSET_STATE,
  INITIAL_LOADING_STATE,
  MODAL,
} from 'constants/pagination';
import { fetchGroups } from '../groups';

const MODAL_GROUPS_INITIAL_STATE: SharedPaginationState = {
  offset: INITIAL_OFFSET_STATE,
  loading: INITIAL_LOADING_STATE,
  numberOfItems: 0,
  itemList: [],
};

export default createReducer(MODAL_GROUPS_INITIAL_STATE, (builder) => {
  builder.addCase(fetchGroups.pending, (state, action) => {
    const { paginationLocation, offset } = action.meta.arg;

    if (paginationLocation !== MODAL) {
      return;
    }
    state.loading = !INITIAL_LOADING_STATE;

    if (offset === INITIAL_OFFSET_STATE) {
      state.offset = INITIAL_OFFSET_STATE;
      state.itemList = [];
    }
  });

  builder.addCase(fetchGroups.fulfilled, (state, action) => {
    if (action.meta.arg.paginationLocation !== MODAL) {
      return;
    }

    const { payload } = action;
    const { numberOfItems, items, offset } = payload;

    state.offset = offset ?? INITIAL_OFFSET_STATE;
    state.loading = INITIAL_LOADING_STATE;

    state.numberOfItems = numberOfItems;

    // itemList changes for a general fetch
    if (state.offset === INITIAL_OFFSET_STATE) {
      state.itemList = items.map((group: GroupEntity) => group.id);
    } else {
      const itemsToAdd = items.reduce((list: string[], group: GroupEntity) => {
        const { id } = group;
        if (!state.itemList.includes(id)) {
          list.push(id);
        }
        return list;
      }, []);

      state.itemList = [...state.itemList, ...itemsToAdd];
    }
  });

  builder.addCase(fetchGroups.rejected, (state, action) => {
    if (action.meta.arg.paginationLocation !== MODAL) {
      return;
    }
    state.loading = INITIAL_LOADING_STATE;
  });
});
