import { createAction, createReducer, nanoid } from '@reduxjs/toolkit';
import { EntityId } from 'fwi-fe-types';

import { AlertType, AlertsState } from 'appTypes';

import { patchAlert, fetchAlerts, upsertAlert } from './api';
import { adapter } from './schema';

export const NEW_ALERT_ID = '__new-alert';

export const INITIAL_ALERTS_STATE: AlertsState = adapter.getInitialState({
  modal: {
    alertId: '',
    visible: false,
    type: 'user',
  },
  loading: true,
});

export interface ShowAlertPayload {
  /**
   * The alert's id if modifying an alert.
   */
  alertId?: EntityId;
  type: AlertType;
}

/**
 * Shows the alert modal for either a new alert or an existing alert.
 *
 * @param payload - {@link ShowAlertPayload}
 */
export const showAlertModal = createAction(
  'alert/showModal',
  function prepare(payload: ShowAlertPayload) {
    const { alertId = `${NEW_ALERT_ID}_${nanoid()}`, type } = payload;

    return { payload: { alertId, type } };
  }
);
export const hideAlertModal = createAction('alert/hideModal');

export default createReducer(INITIAL_ALERTS_STATE, (builder) =>
  builder
    .addCase(showAlertModal, (state, { payload }) => {
      state.modal.alertId = payload.alertId;
      state.modal.type = payload.type;
      state.modal.visible = true;
    })
    .addCase(hideAlertModal, (state) => {
      state.modal.visible = false;
    })
    .addCase(fetchAlerts.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchAlerts.fulfilled, (state, action) => {
      state.loading = false;
      adapter.upsertMany(state, action.payload);
    })
    .addCase(fetchAlerts.rejected, (state) => {
      state.loading = false;
    })
    .addCase(upsertAlert.fulfilled, (state, { payload }) => {
      adapter.upsertOne(state, payload);
    })
    .addCase(patchAlert.pending, (state, action) => {
      const { id, isAlertActive } = action.meta.arg;
      adapter.updateOne(state, {
        id,
        changes: { id, isAlertActive },
      });
    })
    .addCase(patchAlert.rejected, (state, action) => {
      const { id, isAlertActive } = action.meta.arg;
      adapter.updateOne(state, {
        id,
        changes: { id, isAlertActive: !isAlertActive },
      });
    })
);
