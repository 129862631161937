import { ReactElement, ReactNode, Suspense } from 'react';
import { CircularProgress } from 'fwi-fe-components';

export interface ProgressSuspenseProps {
  /**
   * The progress id which is required for a11y.
   */
  id: string;
  children: ReactNode;
}

/**
 * A simple `Suspense` wrapper that defaults to rendering a `CircularProgress`
 * while lazy loading the children.
 */
export function ProgressSuspense({
  id,
  children,
}: ProgressSuspenseProps): ReactElement {
  return (
    <Suspense fallback={<CircularProgress id={id} />}>{children}</Suspense>
  );
}
