import { alphaNumericSort } from 'fwi-fe-utils';
import {
  DeviceFolderEntity,
  EntityId,
  FolderEntity,
  FolderType,
  LibraryFolderEntity,
} from 'fwi-fe-types';

import {
  AppState,
  EntityRecord,
  FolderBreadcrumb,
  FoldersState,
  ReadonlyEntityList,
  ReadonlyEntityRecord,
} from 'appTypes';
import { READY_TO_USE_FOLDER_NAME } from 'constants/folders';
import {
  getFolderNameByFolderType,
  getFoldersFrom,
  getRouteByFolderType,
  isRootFolder,
} from 'utils/folders';
import { LIBRARY_READY_TO_USE } from 'utils/routes';

import { adapter } from './schema';

const getFoldersState = (state: AppState): FoldersState => state.folders;

export const {
  selectById: getFolderById,
  selectAll: getFolders,
  selectEntities: getFolderEntities,
  selectIds: getFolderIds,
  selectTotal: getTotalFolders,
} = adapter.getSelectors(getFoldersState);

/**
 *
 * @param state - The top-level store state.
 * @param folderType - The folder type to get.
 * @returns all the folder entities in state with the provided folderType
 */
export const getFoldersByFolderType = (
  state: AppState,
  folderType: FolderType
): ReadonlyEntityRecord<FolderEntity> => {
  // have to typecast
  const folders = getFolders(state);

  return folders.reduce<EntityRecord<FolderEntity>>((collection, folder) => {
    if (folder.folderType === folderType) {
      collection[folder.folderId] = folder;
    }

    return collection;
  }, {});
};

/**
 *
 * @param state - The top-level store state.
 * @returns all the device folder entities in state as a list.
 */
export const getDeviceFolders = (
  state: AppState
): ReadonlyEntityList<DeviceFolderEntity> =>
  getFolders(state).filter(
    (folder): folder is DeviceFolderEntity =>
      folder.folderType === FolderType.DEVICE
  );

/**
 *
 * @param state - The top-level store state.
 * @returns all the library folder entities in state as a list.
 */
export const getLibraryFolders = (
  state: AppState
): ReadonlyEntityList<LibraryFolderEntity> =>
  getFolders(state).filter(
    (folder): folder is LibraryFolderEntity =>
      folder.folderType === FolderType.LIBRARY
  );

/**
 *
 * @param state - The top-level store state.
 * @returns true of the device folders are currently loading.
 */
export const isLoadingDeviceFolders = (state: AppState): boolean =>
  getFoldersState(state).loadingDevices;

/**
 *
 * @param state - The top-level store state.
 * @returns true of the device folders are currently loading.
 */
export const isLoadingChannelFolders = (state: AppState): boolean =>
  getFoldersState(state).loadingChannels;

/**
 *
 * @param state - The top-level store state.
 * @returns true of the library folders are currently loading.
 */
export const isLoadingLibraryFolders = (state: AppState): boolean =>
  getFoldersState(state).loadingLibrary;

/**
 *
 * @param state - The top-level store state.
 * @param folderType - The folder type to check if loading.
 * @returns true if the folder sevice is loading
 */
export const isLoadingFolders = (
  state: AppState,
  folderType: FolderType
): boolean => {
  if (folderType === FolderType.LIBRARY) {
    return isLoadingLibraryFolders(state);
  }

  return isLoadingDeviceFolders(state);
};

/**
 *
 * @param state - The top-level store state
 * @param folderIds - A list of folder ids to get
 * @param sort - Boolean if the folders should be sorted alphanumerically
 * @returns a list of folders that are optionally sorted alphanumerically.
 */
export const getFoldersByIds = (
  state: AppState,
  folderIds: readonly EntityId[],
  sort = true
): ReadonlyEntityList<FolderEntity> => {
  const folders = folderIds.reduce<FolderEntity[]>((list, folderId) => {
    const folder = getFolderById(state, folderId);
    if (folder) {
      list.push(folder);
    }

    return list;
  }, []);

  if (!sort) {
    return folders;
  }

  return alphaNumericSort(folders, (folder: FolderEntity) => folder.name);
};

/**
 * Creates a list of breadcrumbs starting from the root folder down to the
 * provided `folderId`.
 *
 * @param state - The top-level store state.
 * @param folderType - The folder type that should be used
 * @param folderId - The folder id to use as the starting point to build the
 * list to root
 * @param linkSuffix - An optional suffix to apply to each breadcrumb link. This
 * is mostly used if you want to keep query params or the `/grid` suffix in
 * devices.
 * @returns a list of {@link FolderBreadcrumb} to build breadcrumbs or a folder
 * path
 */
export const getFolderBreadcrumbs = (
  state: AppState,
  folderType: FolderType,
  folderId: string,
  linkSuffix = ''
): ReadonlyEntityList<FolderBreadcrumb> => {
  const prefix = getRouteByFolderType(folderType);
  const suffix = linkSuffix ? `/${linkSuffix}` : '';
  const foldersState = getFoldersByFolderType(state, folderType);
  const folders = getFoldersFrom(foldersState, folderId)
    .folders.slice()
    .reverse();

  const rootFolderBreadcrumb: FolderBreadcrumb = {
    to: `${prefix}${suffix}`,
    name: getFolderNameByFolderType(folderType) || '',
  };

  const breadcrumbs = folders.map<FolderBreadcrumb>((folder) => {
    if (isRootFolder(folder, FolderType.LIBRARY, true)) {
      return {
        to: `${LIBRARY_READY_TO_USE}${suffix}`,
        name: READY_TO_USE_FOLDER_NAME,
      };
    }

    if (isRootFolder(folder)) {
      return rootFolderBreadcrumb;
    }

    const { folderId, name } = folder;
    return {
      to: `${prefix}/folders/${folderId}${suffix}`,
      name,
    };
  });

  if (breadcrumbs.length) {
    return breadcrumbs;
  }

  return [rootFolderBreadcrumb];
};

export interface FolderPathOptions {
  /**
   * The folderId to start the path from to root.
   */
  folderId: EntityId;

  /**
   * The folder type that should be used in the folder path.
   */
  folderType: FolderType;

  /**
   * Boolean if the folder path should end with a `/`.
   *
   * @defaultValue `true`
   */
  trailingSlash?: boolean;
}

/**
 * Creates a folder path string of all the folders in state or starting
 * from a specific folder id building up to root.
 *
 * @param state The top-level store state.
 * @param options The {@link FolderPathOptions} to generate the string
 * @returns the folder path string
 */
export const getFolderPath = (
  state: AppState,
  { folderId, folderType, trailingSlash = true }: FolderPathOptions
): string => {
  const breadcrumbs = getFolderBreadcrumbs(state, folderType, folderId);
  return breadcrumbs.reduce<string>((path, { name }) => {
    const prefix = trailingSlash || !path ? path : `${path}/`;
    const suffix = trailingSlash ? '/' : '';

    return `${prefix}${name}${suffix}`;
  }, '');
};
