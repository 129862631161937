import type { ReactElement } from 'react';
import cn from 'classnames';
import { FormattedMessage, FormattedMessageProps } from 'fwi-fe-components';

import styles from './HelpText.module.scss';

export function HelpText({
  className,
  component = 'div',
  ...props
}: FormattedMessageProps): ReactElement {
  return (
    <FormattedMessage
      {...props}
      component={component}
      className={cn(styles.message, className)}
    />
  );
}
