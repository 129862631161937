import { updateLastInteractionTime } from 'fwi-fe-components';
import { isPidsParamsDefined, removePidsQueryParams } from 'fwi-fe-utils';
import { useEffect } from 'react';

export function RemovePidsQueryParams(): null {
  useEffect(() => {
    if (isPidsParamsDefined()) {
      updateLastInteractionTime();
    }

    removePidsQueryParams();
  }, []);

  return null;
}
