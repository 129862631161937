import type { ReactElement } from 'react';
import {
  DialogButton,
  DialogFooter,
  FormattedMessage,
  useIdPrefix,
} from 'fwi-fe-components';

export interface LabelsModalFooterProps {
  save(): Promise<boolean>;
  saving: boolean;
  isDoneDisabled: boolean;
  onRequestClose(): void;
}

export function LabelsModalFooter({
  save,
  saving,
  isDoneDisabled,
  onRequestClose,
}: LabelsModalFooterProps): ReactElement {
  const id = useIdPrefix();

  return (
    <DialogFooter>
      <DialogButton
        id={`${id}-cancel`}
        theme="stone"
        onClick={onRequestClose}
        disabled={saving}
      >
        <FormattedMessage messageId="Cancel" />
      </DialogButton>
      <DialogButton
        id={`${id}-done`}
        theme="primary"
        onClick={async () => {
          if (await save()) {
            onRequestClose();
          }
        }}
        loading={saving}
        disabled={isDoneDisabled}
      >
        <FormattedMessage messageId="Done" />
      </DialogButton>
    </DialogFooter>
  );
}
