import { ValuesOf } from 'fwi-fe-types';
import { STATUS } from 'fwi-constants';

import { LicenseTypeFilter } from './admin';

const { COMPLETE, FAILED, UPLOADING } = STATUS.STATES.UPLOAD;

export const UploadStatus = {
  COMPLETE,
  FAILED,
  UPLOADING,
} as const;
export type UploadStatus = ValuesOf<typeof UploadStatus>;

export interface UserUploadError {
  message: string;
  column?: string;
  index?: string;
  num?: string;
  licenseType?: LicenseTypeFilter;
}

export interface UserUpload {
  status: UploadStatus;
  fileName: string;
  error?: UserUploadError;
}

export type BulkUserUploads = Record<string, UserUpload>;

export interface BulkUserOpsState {
  visible: boolean;
  uploads: BulkUserUploads;
}
