import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';
import { ReactElement } from 'react';
import { getCompanyId } from 'fwi-fe-utils';

import { getCurrentCompany, useAppSelector } from 'appState';
import { SPLIT_CONFIG } from 'constants/splitConfig';

import { useSplitAttributes } from './useSplitAttributes';

export interface SplitConfigProviderProps {
  children: ReactElement;
}

export function SplitConfigProvider({
  children,
}: SplitConfigProviderProps): ReactElement {
  const companyId = useAppSelector(
    (state) => getCurrentCompany(state)?.id || getCompanyId() || 'anonymous'
  );
  const attributes = useSplitAttributes();

  return (
    <SplitFactory config={SPLIT_CONFIG} updateOnSdkUpdate>
      <SplitClient splitKey={companyId} attributes={attributes}>
        {children}
      </SplitClient>
    </SplitFactory>
  );
}
