import {
  authTokenController,
  FWI_COMPANY_ID,
  FWI_COMPANY_NAME,
  getCompanyId,
  setCookie,
} from 'fwi-fe-utils';
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from '@reduxjs/toolkit';
import { getSearch, replace } from 'connected-react-router';
import { EntityId } from 'fwi-fe-types';

import { acceptEula } from 'appState/eula/api';
import { AppThunkConfig, AuthenticationStatus, AuthState } from 'appTypes';
import { getLoginFrom, removeLoginFrom } from 'utils/auth';
import { logout } from 'utils/logout';
import {
  APP_ORIGIN,
  DASHBOARD,
  EULA,
  getKnownQueryParams,
  isExternalRoute,
  isLogout,
} from 'utils/routes';

import { fetchCurrentUser, fetchIdp, patchCurrentUser } from './api';
import { getCurrentUser } from './selectors';

/**
 * Special token to help show that the email doesn't have an identity provider
 * attached, so the user must login through okta.
 */
export const NO_IDENTITY_PROVIDER = '__NO_IDENTITY_PROVIDER__';

/**
 * This should be used to reset the identity provider for the user which
 * restarts the login process.
 */
export const resetIdp = createAction('auth/resetIdp');

/**
 * Updates the state to show the current authenticated status. This should
 * really only be used during the login flow.
 */
export const authenticated =
  createAction<AuthenticationStatus>('auth/authenticated');

/**
 * A thunked action that will select the user's company and fetch the user again
 * with that new company id.
 *
 * @param companyId - The company id to use
 */
export const selectCompany = createAsyncThunk<void, EntityId, AppThunkConfig>(
  'auth/selectCompany',
  async (companyId, { dispatch, getState }) => {
    const company = getCurrentUser(getState())?.companies.find(
      ({ id }) => id === companyId
    );
    if (!company) {
      throw new Error();
    }

    setCookie(FWI_COMPANY_ID, companyId);
    setCookie(FWI_COMPANY_NAME, company.name);

    const result = await dispatch(fetchCurrentUser());
    if (fetchCurrentUser.rejected.match(result)) {
      return logout();
    }

    dispatch(authenticated('done'));
    const from =
      getKnownQueryParams(getSearch(getState())).from || getLoginFrom(false);
    removeLoginFrom();
    if (result.payload.showEula) {
      let query = '';
      if (from) {
        query = `?from=${encodeURIComponent(from)}`;
      }

      dispatch(replace(`${EULA}${query}`));
      return;
    }

    if (isExternalRoute(from)) {
      window.location.replace(`${APP_ORIGIN}${from}`);
      return;
    }

    dispatch(replace(from || DASHBOARD));
  }
);

export const INITIAL_AUTH_STATE: AuthState = {
  idpId: '',
  status: 'init',
  user: undefined,
  loadingIdp: false,
  loadingUser: false,
};

export const getInitialAuthState = (pathname: string): AuthState => {
  const companyId = getCompanyId();
  const isValid = authTokenController.isAccessTokenValid();

  let status: AuthenticationStatus = 'init';
  if (!isLogout(pathname)) {
    if (isValid && companyId) {
      status = 'done';
    } else if (isValid) {
      status = 'token';
    }
  }

  // if they have a companyId but no access token, they need to start over the
  // login flow since something went wrong

  return {
    ...INITIAL_AUTH_STATE,
    status,
  };
};

export default createReducer(INITIAL_AUTH_STATE, (builder) =>
  builder
    .addCase(authenticated, (state, { payload }) => {
      state.status = payload;
    })
    .addCase(resetIdp, (state) => {
      state.idpId = '';
    })
    .addCase(fetchIdp.pending, (state) => {
      state.loadingIdp = true;
    })
    .addCase(fetchIdp.fulfilled, (state, { payload }) => {
      state.loadingIdp = false;
      state.idpId = payload?.idpId ?? NO_IDENTITY_PROVIDER;
    })
    .addCase(fetchIdp.rejected, (state) => {
      state.loadingIdp = false;
    })
    .addCase(fetchCurrentUser.pending, (state) => {
      state.loadingUser = true;
    })
    .addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
      state.loadingUser = false;
      state.user = payload;
    })
    .addCase(fetchCurrentUser.rejected, (state) => {
      state.loadingUser = false;
    })
    .addCase(patchCurrentUser.fulfilled, (state, action) => {
      const { companies, isTrackAnalyticsAllowed } = action.meta.arg;
      if (!state.user) {
        return;
      }

      state.user.companies = state.user.companies.filter(({ id }) =>
        companies.includes(id)
      );
      state.user.isTrackAnalyticsAllowed = isTrackAnalyticsAllowed;
    })
    .addCase(acceptEula.fulfilled, (state) => {
      if (state.user) {
        state.user.showEula = false;
      }
    })
);
