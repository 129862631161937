import { cloneElement, ReactElement } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { MenuItem, useIntl } from 'fwi-fe-components';

import {
  isMobile,
  moduleSelectedFromBento,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { UserModuleOrLink } from 'appTypes';
import { REACT_APP_FE_ORIGIN } from 'constants/env';
import { isInternalRoute, isValidRoute } from 'utils/routes';

import styles from './ModuleMenuItem.module.scss';

export interface ModuleMenuItemProps extends UserModuleOrLink {
  id: string;
  icon: ReactElement;
}

export default function ModuleMenuItem({
  id,
  href,
  moduleId,
  icon,
  ...props
}: ModuleMenuItemProps): ReactElement {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const mobile = useAppSelector(isMobile);
  let to: string | undefined;
  let updatedHref: string | undefined;
  if (isInternalRoute(href)) {
    to = href;
  } else {
    updatedHref = isValidRoute(href) ? `${REACT_APP_FE_ORIGIN}${href}` : href;
  }

  const name = intl.formatMessage({ id: `Dashboard.${moduleId}` });

  return (
    <MenuItem
      {...props}
      id={id}
      to={to}
      href={updatedHref}
      icon={cloneElement(icon, { className: styles.icon })}
      className={cn(styles.item, {
        [styles.itemMobile]: mobile,
      })}
      linkClassName={styles.link}
      linkComponent={to ? Link : undefined}
      onClick={() => {
        dispatch(moduleSelectedFromBento(name));
      }}
    >
      {name}
    </MenuItem>
  );
}
