import { ReactElement } from 'react';
import { MenuItem } from 'fwi-fe-components';

import {
  createAlertModalDisplayed,
  showAlertModal,
  useAppDispatch,
} from 'appState';
import FormattedMessage from 'components/FormattedMessage';

export default function AddAlertForSomeoneElse(): ReactElement {
  const dispatch = useAppDispatch();

  return (
    <MenuItem
      id="add-new-alert-for-someone-else"
      onClick={() => {
        dispatch(createAlertModalDisplayed());
        dispatch(showAlertModal({ type: 'company' }));
      }}
    >
      <FormattedMessage messageId="Alerts.SendToSomeoneElse" />
    </MenuItem>
  );
}
