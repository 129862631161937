import { ReactElement } from 'react';
import cn from 'classnames';
import { DialogContent, LoadingOverlay } from 'fwi-fe-components';
import { EntityId } from 'fwi-fe-types';

import { isMobile, useAppSelector } from 'appState';

import { LabelSection } from './LabelSection';
import styles from './LabelsModalContent.module.scss';
import { LabelsModalFooter } from './LabelsModalFooter';
import { LabelsModalHeader } from './LabelsModalHeader';
import { useUpsertLabel } from './useUpsertLabel';
import { ValuesSection } from './ValuesSection';

export interface LabelsModalContentProps {
  labelId: EntityId;
  onRequestClose(): void;
}

export function LabelsModalContent({
  labelId,
  onRequestClose,
}: LabelsModalContentProps): ReactElement {
  const mobile = useAppSelector(isMobile);
  const {
    save,
    saving,
    changed,
    editing,
    labelName,
    labelNameError,
    labelInputType,
    searchText,
    errors,
    values,
    addValue,
    removeValue,
    setSearchText,
    updateLabelName,
    updateLabelInputType,
    updateLabelValue,
  } = useUpsertLabel(labelId);

  return (
    <>
      <LabelsModalHeader
        saving={saving}
        editing={editing}
        onSearchChange={setSearchText}
        onRequestClose={onRequestClose}
      />
      <DialogContent
        className={cn(styles.content, mobile && styles.noPaddingLeft)}
      >
        <form className={cn(!mobile && styles.grid)}>
          <LabelSection
            error={labelNameError}
            editing={editing}
            disabled={saving}
            labelName={labelName}
            labelInputType={labelInputType}
            updateLabelName={updateLabelName}
            updateLabelInputType={updateLabelInputType}
          />
          <ValuesSection
            errors={errors}
            values={values}
            disabled={saving}
            searching={!!searchText}
            onAddClick={addValue}
            onValueChange={updateLabelValue}
            onRemoveClick={removeValue}
          />
        </form>
        {saving && <LoadingOverlay />}
      </DialogContent>
      <LabelsModalFooter
        save={save}
        saving={saving}
        isDoneDisabled={!!errors.size || !changed}
        onRequestClose={onRequestClose}
      />
    </>
  );
}
