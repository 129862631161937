import { LiHTMLAttributes, ReactElement } from 'react';
import cn from 'classnames';

import styles from './NoneRoleItem.module.scss';

export interface NoneRoleItemProps extends LiHTMLAttributes<HTMLLIElement> {
  subheader?: boolean;
}

export default function NoneRoleItem({
  className,
  children,
  subheader = false,
  ...props
}: NoneRoleItemProps): ReactElement {
  return (
    <li
      role="none"
      {...props}
      className={cn(
        styles.item,
        {
          [styles.subheader]: subheader,
        },
        className
      )}
    >
      {children}
    </li>
  );
}
