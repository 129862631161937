import { PayloadAction } from '@reduxjs/toolkit';

import { AnalyticsEvent, AnalyticsProperties } from 'appTypes';

import { analyticsTrackEvent } from './actions';

/**
 * The analytics event to trigger whenever the customer search is used.
 *
 * @param eventName The search event name
 * @param properties Any additional analytics data to send.
 */
export const customersAnalyticsEvent = (
  eventName: string,
  properties?: AnalyticsProperties
): PayloadAction<AnalyticsEvent> => {
  return analyticsTrackEvent({
    eventName,
    properties: {
      ...properties,
      category: 'Customers',
    },
  });
};
