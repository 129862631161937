import { replace, getLocation } from 'connected-react-router';
import { put, select, take, takeEvery } from 'redux-saga/effects';
import { CurrentUserEntity } from 'fwi-fe-types';
import { getCompanyId } from 'fwi-fe-utils';

import { acceptEula, addToast, analyticsTrackEvent } from 'appState';
import { AuthenticationStatus } from 'appTypes';
import { logout } from 'utils/logout';
import { EULA, isEula, isLogin, isUnifiedDashboard } from 'utils/routes';

import { fetchCurrentUser, patchCurrentUser } from './api';
import { authenticated } from './reducer';
import { getAuthStatus, getCurrentUser } from './selectors';

function* handleInitialAuthorized() {
  type TakeResult = ReturnType<
    typeof fetchCurrentUser.fulfilled | typeof fetchCurrentUser.rejected
  >;

  yield put(fetchCurrentUser());
  const action: TakeResult = yield take([
    fetchCurrentUser.fulfilled.type,
    fetchCurrentUser.rejected.type,
  ]);

  if (fetchCurrentUser.rejected.match(action)) {
    return false;
  }

  if (!action.payload.showEula) {
    return true;
  }

  const pathname: string = yield select((state) => getLocation(state).pathname);
  if (!isEula(pathname)) {
    let query = '';
    if (!isLogin(pathname) && !isUnifiedDashboard(pathname)) {
      query = `?from=${encodeURIComponent(pathname)}`;
    }

    yield put(replace(`${EULA}${query}`));
  }

  yield take(acceptEula.fulfilled.type);
  return true;
}

/**
 * This saga will continue to run until the user has been fully authenticated.
 *
 * @see {@link https://fourwindsinteractive.atlassian.net/wiki/spaces/CLOUD/pages/1573552212/Authentication+and+Authorization+Flow}
 */
export function* waitForAuthentication() {
  let status: AuthenticationStatus = yield select(getAuthStatus);
  if (status === 'done') {
    const isLoggedIn: boolean = yield handleInitialAuthorized();
    if (isLoggedIn) {
      return;
    }
  }

  while (status !== 'done') {
    const action: ReturnType<typeof authenticated> = yield take(
      authenticated.type
    );
    status = action.payload;
  }
}

export function* watchForSettingChanges() {
  type TakeResult = ReturnType<
    typeof patchCurrentUser.fulfilled | typeof patchCurrentUser.rejected
  >;

  yield takeEvery(patchCurrentUser.pending.type, function* handler() {
    // need to take the user on the pending type since the success modifies the
    // user with the patch.
    const user: CurrentUserEntity = yield select(getCurrentUser);
    const action: TakeResult = yield take([
      patchCurrentUser.fulfilled.type,
      patchCurrentUser.rejected.type,
    ]);

    if (patchCurrentUser.rejected.match(action)) {
      yield put(addToast({ messageId: 'Toasts.PatchSettingsFailure' }));
      return;
    }

    const { isTrackAnalyticsAllowed, companies } = action.meta.arg;
    if (user.isTrackAnalyticsAllowed !== isTrackAnalyticsAllowed) {
      yield put(
        analyticsTrackEvent({
          eventName: `Analytics ${
            isTrackAnalyticsAllowed ? 'Enabled' : 'Disabled'
          }`,
          properties: {
            category: 'My Settings',
          },
          disableAnalyticsCheck: true,
        })
      );
    }

    if (!companies.find((id) => id === getCompanyId())) {
      logout();
      return;
    }

    yield put(addToast({ messageId: 'Toasts.PatchSettingsSuccess' }));
  });
}
