import { fork, takeLatest, put } from 'redux-saga/effects';

import { addToast } from 'appState';

import { putCompanySettings } from './api';

export function* watchSettingsPutSuccesses() {
  yield takeLatest(
    putCompanySettings.fulfilled.type,
    function* toastSettingsPutSuccess() {
      yield put(addToast({ messageId: 'PutSettingsSuccess' }));
    }
  );
}

export function* watchSettingsPutFailures() {
  yield takeLatest(
    putCompanySettings.rejected.type,
    function* toastSettingsPutFailure() {
      yield put(addToast({ messageId: 'PutSettingsFailure' }));
    }
  );
}

export function* companySettingsSagas() {
  yield fork(watchSettingsPutSuccesses);
  yield fork(watchSettingsPutFailures);
}
