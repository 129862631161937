import { useCallback, useState } from 'react';
import { EntityId, ReadonlyEntityList, UserCompany } from 'fwi-fe-types';
import {
  FWI_COMPANY_ID,
  FWI_COMPANY_NAME,
  authTokenController,
  getCompanyId,
  setCookie,
} from 'fwi-fe-utils';

import {
  accountChanged,
  getCurrentCompany,
  getCurrentUser,
  getUserCompanies,
  isLoadingCurrentUser,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { isFeatureFlagEnabledForCompany } from 'utils/split';
import { isDigitalSignageAuth, removeTokenControllers } from 'utils/tokens';
import { useSplitFeaturesForTesting } from './useSplit';

export interface ChangeCompanyResult {
  loading: boolean;
  companyId: EntityId;
  companies: ReadonlyEntityList<UserCompany>;
  handleCompanyChange(
    nextCompanyId: EntityId,
    nextCompanyName: string
  ): Promise<void>;
}
export function useChangeCompany(): ChangeCompanyResult {
  const [changing, setChanging] = useState(false);
  const user = useAppSelector(getCurrentUser);
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(
    (state) => getCurrentCompany(state)?.id ?? getCompanyId()
  );
  const companies = useAppSelector(getUserCompanies);
  const loadingUser = useAppSelector(isLoadingCurrentUser);
  const { features, authorizationKey } = useSplitFeaturesForTesting();

  return {
    loading: changing || loadingUser,
    companyId,
    companies,
    handleCompanyChange: useCallback(
      async (nextCompanyId, nextCompanyName) => {
        const nextCompany = companies.find(
          (company) => company.id === nextCompanyId
        );
        if (companyId === nextCompanyId || !nextCompany) {
          return;
        }

        setChanging(true);
        if (
          isDigitalSignageAuth() ||
          (await isFeatureFlagEnabledForCompany({
            user,
            company: nextCompany,
            features,
            featureFlag: 'UNIFIED_LOGIN_AND_LANDING',
            authorizationKey,
          }))
        ) {
          await authTokenController.exchangeToDigitalSignageToken(
            nextCompanyId
          );
        } else {
          try {
            // refresh the access token just so the user doesn't get logged out
            // immediately once the page reloads if their session was close to
            // expiring when they changed companies
            await authTokenController.refreshAccessToken();
          } catch (e) {
            // for some reason we have no error handling for this
            setChanging(false);

            // close the menu
            document.body.click();
            return;
          }
        }

        dispatch(accountChanged());
        setCookie(FWI_COMPANY_ID, nextCompanyId);
        setCookie(FWI_COMPANY_NAME, nextCompanyName);
        removeTokenControllers();
        window.location.reload();
      },
      [authorizationKey, companies, companyId, dispatch, features, user]
    ),
  };
}
