import { HTMLAttributes, ReactElement, ReactNode, useState } from 'react';
import cn from 'classnames';
import {
  DropdownMenu,
  PlusCircleIcon,
  PlusCircleOutlineIcon,
  useIntl,
} from 'fwi-fe-components';

import { isMobile, useAppSelector } from 'appState';
import { HEADER_BUTTON } from 'constants/branding';
import FormattedMessage from 'components/FormattedMessage';

import styles from './NewContentButton.module.scss';

export interface NewContentButtonProps extends HTMLAttributes<HTMLDivElement> {
  theme?: 'clear' | 'purple';
  children: ReactNode;
  mobileText?: boolean;
  buttonClassName?: string;
}

export default function NewContentButton({
  id = 'new-content',
  className,
  theme = 'clear',
  mobileText = false,
  buttonClassName,
  children,
  ...props
}: NewContentButtonProps): ReactElement {
  const intl = useIntl();
  const mobile = useAppSelector(isMobile);
  const [visible, setVisible] = useState(false);
  const icon = visible ? <PlusCircleIcon /> : <PlusCircleOutlineIcon />;

  let ariaLabel: string | undefined;
  let buttonType: 'icon' | 'text' = 'text';
  let buttonIcon: ReactNode = icon;
  let buttonChildren: ReactNode = <FormattedMessage messageId="New" />;
  if (mobile && !mobileText) {
    ariaLabel = intl.formatMessage({ id: 'New' });
    buttonIcon = null;
    buttonType = 'icon';
    buttonChildren = icon;
  }

  return (
    <DropdownMenu
      {...props}
      id={id}
      aria-label={ariaLabel}
      isSheet={mobile}
      buttonTheme={theme === 'purple' ? 'primary' : undefined}
      buttonType={buttonType}
      buttonIcon={buttonIcon}
      buttonIconAfter={false}
      buttonChildren={buttonChildren}
      buttonClassName={cn(
        {
          [styles.button]: theme === 'clear',
        },
        HEADER_BUTTON,
        buttonClassName
      )}
      visible={visible}
      onVisibilityChange={setVisible}
      className={cn(styles.container, className)}
    >
      {children}
    </DropdownMenu>
  );
}
