import { ReactElement } from 'react';
import cn from 'classnames';

import FormattedMessage, {
  FormattedMessageProps,
} from 'components/FormattedMessage';

import styles from './Heading.module.scss';

export function Heading({
  className,
  ...props
}: FormattedMessageProps): ReactElement {
  return (
    <FormattedMessage className={cn(styles.heading, className)} {...props} />
  );
}
