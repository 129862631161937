import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import type { ISplitContextValues } from '@splitsoftware/splitio-react/types/types';
import { REACT_APP_SPLIT_KEY } from 'constants/env';
import type { FeatureFlag } from 'fwi-fe-types';
import { useContext } from 'react';
import { SplitFeaturesForTesting } from 'utils/split';

/**
 * A convenience wrapper for getting the current split context. This should
 * really only be used to check if the SDK has been initialized.
 *
 * @example
 * ```tsx
 * const loading = !useSplit().isReady;
 *
 * return <LoadingOrContent loading={loading}>Content</LoadingOrContent>;
 * ```
 */
export function useSplit(): ISplitContextValues {
  return useContext(SplitContext);
}

/**
 * This is added so that the `isFeatureFlagEnabledForCompany` util works in tests
 */
export function useSplitFeaturesForTesting(): SplitFeaturesForTesting {
  const { factory } = useSplit();
  const features = factory?.settings.features;
  return {
    features: typeof features === 'string' ? undefined : features,
    authorizationKey:
      factory?.settings.core.authorizationKey ?? REACT_APP_SPLIT_KEY,
  };
}

/**
 * A convenience wrapper around the {@link useTreatments} from split to only
 * return a single split's value instead of a record.
 *
 * @example
 * ```tsx
 * import { useSplitTreatment } from 'hooks/useSplit';
 * import { DASHBOARD } from 'utils/routes';
 *
 * function Example() {
 *   const dashboardRedirect = useSplitTreatment('UNIFIED_LOGIN_AND_LANDING');
 *
 *   if (dashboardRedirect !== 'off') {
 *     return <a href={dashboardRedirect}>Dashboard</a>;
 *   }
 *
 *   return <Link to={DASHBOARD}>Dashboard</Link>;
 * }
 * ```
 */
export function useSplitTreatment(name: FeatureFlag): string {
  return useTreatments([name])[name]?.treatment ?? '';
}

/**
 * This is a convenience wrapper around the {@link useTreatments} from split
 * when we use the on/off treatments for feature toggles.
 *
 * @example
 * ```tsx
 * function Example() {
 *   const isFirefoxEnabled = useSplitEnabled('DS_FIREFOX');
 *
 *   if (!isFirefoxEnabled && isFirefox()) {
 *     return null;
 *   }
 *
 *   return <SomeContent />;
 * }
 * ```
 */
export function useSplitEnabled(name: FeatureFlag): boolean {
  return useSplitTreatment(name) === 'on';
}

/**
 * Used to get an integer value from split with a fallback value.
 *
 * @example
 * ```tsx
 * function Example() {
 *   const inactivityTime = useSplitInteger('DS_INACTIVITY_TIME', 30);
 *
 *   // do stuff with inactivityTime. Always a number
 * }
 * ```
 */
export function useSplitInteger(name: FeatureFlag, fallback: number): number {
  const treatment = useSplitTreatment(name);
  const parsedTreatment = parseInt(treatment, 10);
  if (Number.isNaN(parsedTreatment)) {
    return fallback;
  }

  return parsedTreatment;
}
