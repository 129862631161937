import { analyticsTrackEvent } from './actions';

const category = 'Labels';

/**
 * The analytics event to trigger whenever the create label modal becomes
 * visible within admin center.
 */
export const createLabelModalOpened = analyticsTrackEvent.bind(null, {
  eventName: 'Create Label Modal Opened',
  properties: { category },
});

/**
 * The analytics event to trigger whenever a new label is created within admin
 * center.
 */
export const labelCreated = analyticsTrackEvent.bind(null, {
  eventName: 'New Label Created',
  properties: { category },
});

/**
 * The analytics event to trigger whenever the edit label modal becomes visible
 * within admin center.
 */
export const labelEdited = analyticsTrackEvent.bind(null, {
  eventName: 'Label Edited',
  properties: { category },
});

/**
 * The analytics event to trigger whenever a label is deleted within admin
 * center.
 */
export const labelDeleted = analyticsTrackEvent.bind(null, {
  eventName: 'Label Deleted',
  properties: { category },
});
