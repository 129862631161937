import './index.scss';
import { ApolloProvider } from '@apollo/client';
import { ConnectedRouter } from 'connected-react-router';
import {
  HoverModeProvider,
  InteractionModeProvider,
  NestedDialogContextProvider,
  PortalProvider,
} from 'fwi-fe-components';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { history, store } from './appState';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import { IntlProvider } from './components/IntlProvider';
import SplitConfigProvider from './components/SplitConfigProvider';
import SupportedBrowserCheck from './components/SupportedBrowserCheck';
import { apolloClient } from './constants/apolloClient';
import { UnifiedProvider } from './components/UnifiedProvider';
import { initFromOtherProduct } from './utils/auth';

if ('serviceWorker' in navigator) {
  // have to unregister all existing SW behavior until end of time
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}
initFromOtherProduct(history);

render(
  <ApolloProvider client={apolloClient}>
    <HoverModeProvider>
      <NestedDialogContextProvider>
        <PortalProvider renderNode={document.body}>
          <InteractionModeProvider>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <IntlProvider>
                  <SplitConfigProvider>
                    <SupportedBrowserCheck>
                      <ErrorBoundary>
                        <DndProvider backend={HTML5Backend}>
                          <UnifiedProvider>
                            <App />
                          </UnifiedProvider>
                        </DndProvider>
                      </ErrorBoundary>
                    </SupportedBrowserCheck>
                  </SplitConfigProvider>
                </IntlProvider>
              </ConnectedRouter>
            </Provider>
          </InteractionModeProvider>
        </PortalProvider>
      </NestedDialogContextProvider>
    </HoverModeProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
