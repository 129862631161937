import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { FilterButton } from 'fwi-fe-components';

import {
  isMobile,
  isSearchFiltersVisible,
  toggleSearchFilters,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { HEADER_BUTTON } from 'constants/branding';
import { isAdminUsers } from 'utils/routes';

export interface AdminFilterButtonProps {
  id: string;
}

export default function AdminFilterButton({
  id,
}: AdminFilterButtonProps): ReactElement | null {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const mobile = useAppSelector(isMobile);
  const visible = useAppSelector(isSearchFiltersVisible);

  if (!isAdminUsers(pathname)) {
    return null;
  }

  return (
    <FilterButton
      id={id}
      onClick={() => {
        dispatch(toggleSearchFilters());
      }}
      mobile={mobile}
      visible={visible}
      buttonClassName={HEADER_BUTTON}
    />
  );
}
