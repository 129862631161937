import { createAction, createReducer } from '@reduxjs/toolkit';
import { UserEntity, UserStatus } from 'fwi-fe-types';

import { UsersState } from 'appTypes';
import { fetchGroupsByUserId, deleteGroup } from 'appState/groups';

import {
  fetchUsers,
  fetchUsersByGroupId,
  fetchUser,
  putUser,
  postUser,
  deleteUser,
  unlockUserAccount,
} from './api';
import { adapter } from './schema';

export const INITIAL_USERS_STATE: UsersState = adapter.getInitialState({
  loading: false,
  loadingIds: [],
  loadingByGroupIds: [],
  loadingDeleteIds: [],
});

export const addUsersFromReduxQuery =
  createAction<readonly UserEntity[]>('users/addFromQuery');

export default createReducer(INITIAL_USERS_STATE, (builder) =>
  builder
    .addCase(addUsersFromReduxQuery, (state, action) => {
      adapter.upsertMany(state, action.payload);
    })
    .addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchUsers.fulfilled, (state, action) => {
      state.loading = false;
      adapter.upsertMany(state, action.payload.items);
    })
    .addCase(fetchUsers.rejected, (state) => {
      state.loading = false;
    })
    .addCase(fetchUser.pending, (state, action) => {
      const id = action.meta.arg;
      if (!state.loadingIds.includes(id)) {
        state.loadingIds.push(id);
      }
    })
    .addCase(fetchUser.fulfilled, (state, action) => {
      const id = action.meta.arg;
      if (state.loadingIds.includes(id)) {
        state.loadingIds.splice(state.loadingIds.indexOf(id), 1);
      }

      adapter.upsertOne(state, action.payload);
    })
    .addCase(fetchUser.rejected, (state, action) => {
      const id = action.meta.arg;
      if (state.loadingIds.includes(id)) {
        state.loadingIds.splice(state.loadingIds.indexOf(id), 1);
      }
    })
    .addCase(postUser.fulfilled, (state, action) => {
      adapter.upsertOne(state, action.payload);
    })
    .addCase(putUser.fulfilled, (state, action) => {
      adapter.upsertOne(state, action.payload);
    })
    .addCase(unlockUserAccount.fulfilled, (state, action) => {
      const id = action.meta.arg;
      adapter.updateOne(state, {
        id,
        changes: { status: UserStatus.ACTIVE },
      });
    })
    .addCase(deleteUser.pending, (state, action) => {
      state.loadingDeleteIds.push(action.meta.arg.userId);
    })
    .addCase(deleteUser.fulfilled, (state, action) => {
      const id = action.meta.arg.userId;

      state.loadingDeleteIds = state.loadingDeleteIds.filter(
        (userId) => userId !== id
      );
      adapter.removeOne(state, id);
    })
    .addCase(deleteUser.rejected, (state, action) => {
      const id = action.meta.arg.userId;
      state.loadingDeleteIds = state.loadingDeleteIds.filter(
        (userId) => userId !== id
      );
    })
    .addCase(fetchUsersByGroupId.pending, (state, action) => {
      const groupId = action.meta.arg;
      if (!state.loadingByGroupIds.includes(groupId)) {
        state.loadingByGroupIds.push(groupId);
      }
    })
    .addCase(fetchUsersByGroupId.fulfilled, (state, action) => {
      const groupId = action.meta.arg;
      if (state.loadingByGroupIds.includes(groupId)) {
        state.loadingByGroupIds.splice(
          state.loadingByGroupIds.indexOf(groupId),
          1
        );
      }

      adapter.upsertMany(state, action.payload);
    })
    .addCase(fetchUsersByGroupId.rejected, (state, action) => {
      const groupId = action.meta.arg;
      if (state.loadingByGroupIds.includes(groupId)) {
        state.loadingByGroupIds.splice(
          state.loadingByGroupIds.indexOf(groupId),
          1
        );
      }
    })
    .addCase(deleteGroup.fulfilled, (state, action) => {
      const { groupId } = action.meta.arg;
      Object.values(state.entities).forEach((user) => {
        if (!user?.groups.includes(groupId)) {
          return;
        }

        user.groups = user.groups.filter((id) => id !== groupId);
      });
    })
    .addCase(fetchGroupsByUserId.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const groups = action.payload.map(({ id }) => id);
      adapter.updateOne(state, {
        id,
        changes: { groups },
      });
    })
);
