import { nanoid } from '@reduxjs/toolkit';
import {
  FWI_ACCESS_TOKEN,
  FWI_AUTH_FROM,
  FWI_AUTH_NONCE,
  FWI_AUTH_STATE,
  FWI_COMPANY_ID,
  FWI_COMPANY_NAME,
  FWI_ID_TOKEN,
  getCookie,
  removeCookie,
  setCookie,
} from 'fwi-fe-utils';
import type { History } from 'history';

import { FROM_AUTHENTICATION_QUERY_PARAM } from 'constants/auth';
import { OKTA_STATE_COOKIE } from 'constants/cookies';
import { getKnownQueryParams, isValidRoute } from './routes';
import { removeTokenControllers } from './tokens';

export interface SecurityCookies {
  /**
   * This is currently the url to return to, but should really be a `nanoid`
   * like the `nonce`. If the `state` we get back after interfacing with Okta
   * doesn't match the cookie we have stored, we know somethings gone wrong and
   * stop the login process.
   */
  state: string;

  /**
   * A `nanoid` that is used to help ensure security during the login process.
   */
  nonce: string;
}

/**
 * This creates two cookies to help ensure that there hasn't been a security
 * breach during the login process to Okta. If we get a different `state` value
 * after navigating to Okta that what we have stored in our cookie, we know
 * something has gone wrong and need to start the login process again.
 */
export function createSecurityCookies(): SecurityCookies {
  const nonce = nanoid(14);
  const state = nanoid(14);
  setCookie(FWI_AUTH_NONCE, nonce);
  setCookie(FWI_AUTH_STATE, state);

  return { state, nonce };
}

/**
 * Gets the `from` state during the login process.
 *
 * @param asQueryParam - Boolean if the `from` value should be returned as a
 * **leading** query param. (`?from=${FROM}`)
 * @returns the from value or an empty string
 */
export function getLoginFrom(asQueryParam: boolean): string {
  const from = getCookie(FWI_AUTH_FROM);
  if (!isValidRoute(from)) {
    return '';
  }

  if (!asQueryParam) {
    return from;
  }

  return `?from=${encodeURIComponent(from)}`;
}

/**
 * This should be called once the login flow has completed.
 */
export function removeLoginFrom(): void {
  removeCookie(FWI_AUTH_FROM);
}

/**
 * This removes the temporary security cookies created with
 * {@link createSecurityCookies}.
 */
export function removeSecurityCookies(): void {
  removeCookie(FWI_AUTH_NONCE);
  removeCookie(FWI_AUTH_STATE);
}

/**
 * This removes all the cookies we've stored that help determine the current
 * user and should be called during the logout process.
 */
export function removeAuthenticationCookies(): void {
  removeLoginFrom();
  removeSecurityCookies();
  removeCookie(FWI_ID_TOKEN);
  removeCookie(FWI_ACCESS_TOKEN);
  removeCookie(FWI_COMPANY_ID);
  removeCookie(FWI_COMPANY_NAME);
}

export interface ParsedCallbackParams {
  code: string;
  error: string;
  from: string;
}

/**
 * This parses the search string from the `/login/callback` route to verify that
 * there hasn't been a man in the middle attack by comparing the `state` query
 * param to our local state param.
 *
 * @param search - The current search string
 * @returns validated and parsed parameters
 */
export function parseCallbackParams(search: string): ParsedCallbackParams {
  const params = getKnownQueryParams(search);
  const { code, state } = params;
  let { error } = params;
  const from = getLoginFrom(false);

  if (!error) {
    // IdentityProvider callback will use FWI_AUTH_STATE while others will use okta state
    const localState =
      getCookie(FWI_AUTH_STATE) || getCookie(OKTA_STATE_COOKIE);

    if (localState !== state) {
      error = 'SignInFailed';
    }
  }

  removeSecurityCookies();

  return {
    code,
    from,
    error,
  };
}

/**
 * This handles a new authentication flow where we create a new self-signed
 * token instead of an okta token by clearing any sessionStorage/localStorage
 * state before rendering the react app
 */
export function initFromOtherProduct(history: History): void {
  const nextParams = new URLSearchParams(history.location.search);
  if (!nextParams.has(FROM_AUTHENTICATION_QUERY_PARAM)) {
    return;
  }

  nextParams.delete(FROM_AUTHENTICATION_QUERY_PARAM);
  removeTokenControllers();
  history.replace({ search: nextParams.toString() });
}
