import cn from 'classnames';
import {
  forwardRef,
  HTMLAttributes,
  InputHTMLAttributes,
  ReactNode,
  Ref,
} from 'react';

import styles from './BrandedTextField.module.scss';

export interface BrandedTextFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: ReactNode;
  containerProps?: HTMLAttributes<HTMLDivElement> & {
    ref?: Ref<HTMLDivElement>;
  };
  active?: boolean;
  afterAddon?: ReactNode;
}

export const BrandedTextField = forwardRef<
  HTMLInputElement,
  BrandedTextFieldProps
>(function BrandedTextField(
  {
    active,
    label,
    type = 'text',
    className,
    placeholder,
    containerProps,
    children,
    afterAddon,
    ...props
  },
  ref
) {
  const { id, disabled } = props;
  return (
    <div
      {...containerProps}
      className={cn(
        styles.container,
        disabled && styles.disabled,
        active && styles.active,
        className
      )}
    >
      {children}
      <input
        {...props}
        ref={ref}
        type={type}
        className={styles.input}
        placeholder={placeholder || ' '}
        data-testid="branded-select-input-value"
      />
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      {afterAddon}
    </div>
  );
});
