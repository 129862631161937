import { ReactElement, ReactNode } from 'react';
import cn from 'classnames';

import AppSpecificActions from 'components/AppSpecificActions';

import styles from './Header.module.scss';
import FWICloud from './FWICloud';
import AppTitle from './AppTitle';
import HeaderActions from './HeaderActions';

export interface HeaderProps {
  title: ReactNode;
  errored: boolean;
  stacked: boolean;
  authenticated: boolean;
}

export default function Header({
  title,
  errored,
  stacked,
  authenticated,
}: HeaderProps): ReactElement {
  if (errored || !authenticated) {
    return <FWICloud unifiedLogoColor={errored ? 'white' : 'black'} />;
  }

  return (
    <>
      <div className="gradient-header" />
      {stacked && (
        <div className={styles.row}>
          <FWICloud />
          <HeaderActions />
        </div>
      )}
      <div className={cn(styles.row, styles.rowSecondary)}>
        <AppTitle>{title}</AppTitle>
        <AppSpecificActions />
      </div>
    </>
  );
}
