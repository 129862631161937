import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { DeviceIcon, Search } from 'fwi-fe-components';

import {
  clearCustomersSearchResults,
  customersAnalyticsEvent,
  getCustomersSearch,
  isMobile,
  searchCustomers,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { CustomerStatus } from 'appTypes';
import { CUSTOMERS_ACTIVE, toEntity } from 'utils/routes';

const constructHref = (item) => {
  const { contentId } = item;
  if (!contentId) {
    return CUSTOMERS_ACTIVE;
  }

  return toEntity(contentId, 'customer', CustomerStatus.ACTIVE);
};

const getResultProps = (item) => ({
  href: constructHref(item),
  icon: <DeviceIcon />,
  linkComponent: Link,
});

export default function CustomersSearch() {
  const dispatch = useAppDispatch();
  const mobile = useAppSelector(isMobile);
  const { loading, ...results } = useAppSelector(getCustomersSearch);
  const executeSearch = useCallback(
    (keywords) => {
      dispatch(searchCustomers(keywords));
    },
    [dispatch]
  );
  const analyticsEvent = useCallback(
    (...args) => {
      dispatch(customersAnalyticsEvent(...args));
    },
    [dispatch]
  );
  const clearSearchResults = useCallback(() => {
    dispatch(clearCustomersSearchResults());
  }, [dispatch]);
  return (
    <Search
      id="header-search"
      results={results}
      loading={loading}
      getResultProps={getResultProps}
      mobile={mobile}
      executeSearch={executeSearch}
      analyticsEvent={analyticsEvent}
      clearSearchResults={clearSearchResults}
    />
  );
}
