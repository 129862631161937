import { LiHTMLAttributes, ReactElement } from 'react';
import cn from 'classnames';
import { CircularProgress } from 'fwi-fe-components';

import NoneRoleItem from 'components/NoneRoleItem';

import styles from './LoadingMenuItem.module.scss';

export interface LoadingMenuItemProps extends LiHTMLAttributes<HTMLLIElement> {
  id: string;
  progressClassName?: string;
}

export default function LoadingMenuItem({
  id,
  progressClassName,
  ...props
}: LoadingMenuItemProps): ReactElement {
  return (
    <NoneRoleItem {...props}>
      <CircularProgress
        id={id}
        centered={false}
        className={cn(styles.progress, progressClassName)}
      />
    </NoneRoleItem>
  );
}
