import { useEffect, useState } from 'react';
import { PaginationRequestOptions, SortOrder, UserEntity } from 'fwi-fe-types';

import { baseApi } from 'appState/baseApi';
import { ELASTIC_SEARCH_MAX_WINDOW_SIZE } from 'constants/constraints';
import { USERS_SEARCH_ENDPOINT } from 'constants/endpoints';
import { format } from 'utils/api';

import { UsersResponseData } from './api';

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    usersSearch: builder.query<
      UsersResponseData,
      Partial<PaginationRequestOptions> | void
    >({
      providesTags: ['Users'],
      query: ({
        size = 24,
        offset = 0,
        sort = 'name',
        sortOrder = 'asc',
      } = {}) => ({
        url: format(USERS_SEARCH_ENDPOINT),
        method: 'POST',
        body: {
          size,
          offset,
          sortBy: sort,
          sortOrder,
        },
      }),
    }),
  }),
});

export const { useUsersSearchQuery, useLazyUsersSearchQuery } = usersApi;

export interface GetAllUsersQueryOptions {
  skip?: boolean;
  size?: number;
  sort?: string;
  sortOrder?: SortOrder;
}

/**
 * This hook uses `@reduxjs/toolkit/query` to fetch **all** users within the
 * company sorted by name.
 */
// eslint-disable-next-line
export function useGetAllUsersQuery({
  skip = false,
  size = -1,
  sort = 'name',
  sortOrder = 'asc',
}: GetAllUsersQueryOptions) {
  interface State {
    users: readonly UserEntity[];
    offset: number;
  }

  const [{ users, offset }, setState] = useState<State>({
    users: [],
    offset: 0,
  });
  const [trigger, { data, ...result }] = useLazyUsersSearchQuery();
  useEffect(() => {
    if (skip || offset >= ELASTIC_SEARCH_MAX_WINDOW_SIZE) {
      return;
    }

    trigger({ size, offset, sort, sortOrder }, true);
  }, [offset, size, skip, sort, sortOrder, trigger]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setState(({ users, offset }) => {
      const nextUsers = [...users, ...data.items];
      let nextOffset = offset;
      if (data.numberOfItems > nextUsers.length) {
        nextOffset += data.items.length;
      }

      return {
        users: nextUsers,
        offset: nextOffset,
      };
    });
  }, [data]);

  return {
    ...result,
    data: users,
  };
}
