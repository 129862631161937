import { lazy, ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ProgressSuspense } from 'components/LazyLoading';
import Login from 'components/Login';
import Logout from 'components/Logout';
import LogoutOkta from 'components/LogoutOkta';
import MaintenancePage from 'components/MaintenancePage';
import SecureRoute from 'components/SecureRoute';
import SessionModal from 'components/SessionModal';
import Toasts from 'components/Toasts';
import {
  ADMIN_SCOPES,
  ALERTS_SCOPES,
  CUSTOMERS_SCOPES,
  REPORTING_SCOPES,
} from 'constants/auth';
import {
  ADMIN,
  ALERTS,
  CUSTOMERS,
  DASHBOARD,
  HOME,
  LOGIN,
  LOGOUT,
  LOGOUT_OKTA,
  REPORTING_PATH,
} from 'utils/routes';

import { SetHtmlClassNameProvider } from './HtmlClassNameContext';
import { Layout } from './Layout';
import { RemovePidsQueryParams } from './RemovePidsQueryParams';
import { useAppLayout } from './useAppLayout';
import { useSplitEnabled } from 'hooks/useSplit';

const MainPropertiesPanel = lazy(() => import('./MainPropertiesPanel'));
const Alerts = lazy(() => import('components/Alerts'));
const Reporting = lazy(() => import('components/Reporting'));
const NotFoundPage = lazy(() => import('components/NotFoundPage'));
const Customers = lazy(() => import('components/Customers'));
const AdminCenter = lazy(() => import('components/AdminCenter'));

export default function App(): ReactElement {
  const { setHtmlClassName, ...layoutProps } = useAppLayout();
  const isMaintenanceEnabled = useSplitEnabled('DS_MAINTENANCE');

  return (
    <SetHtmlClassNameProvider value={setHtmlClassName}>
      <Layout {...layoutProps}>
        <MaintenancePage maintenance={isMaintenanceEnabled}>
          <ProgressSuspense id="loading-route">
            <Switch>
              <Route path={LOGIN} component={Login} />
              <Route path={LOGOUT} component={Logout} />
              <Route path={LOGOUT_OKTA} component={LogoutOkta} />
              <SecureRoute>
                <MainPropertiesPanel />
                <RemovePidsQueryParams />
                <Switch>
                  <Redirect from={HOME} to={DASHBOARD} exact />
                  <SecureRoute
                    path={ALERTS}
                    exact
                    scopes={ALERTS_SCOPES}
                    component={Alerts}
                  />
                  <SecureRoute
                    path={REPORTING_PATH}
                    exact
                    scopes={REPORTING_SCOPES}
                    component={Reporting}
                  />
                  <SecureRoute
                    path={ADMIN}
                    component={AdminCenter}
                    scopes={ADMIN_SCOPES}
                  />
                  <SecureRoute
                    path={CUSTOMERS}
                    scopes={CUSTOMERS_SCOPES}
                    component={Customers}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              </SecureRoute>
            </Switch>
          </ProgressSuspense>
          <Toasts />
          <SessionModal />
        </MaintenancePage>
      </Layout>
    </SetHtmlClassNameProvider>
  );
}
