import { Fragment, lazy, ReactElement, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { removeLastInteractionTime } from 'fwi-fe-components';

import { getCurrentUser, isAuthenticated, useAppSelector } from 'appState';
import { removeAuthenticationCookies } from 'utils/auth';
import {
  COMPANY,
  DASHBOARD,
  EULA,
  isEula,
  LOGIN,
  LOGIN_CALLBACK,
  OKTA_LOGIN,
} from 'utils/routes';
import { removeTokenControllers } from 'utils/tokens';

import SecureRoute from 'components/SecureRoute';

import EmailLogin from './EmailLogin';
import { UnifiedLoginWrapper } from './UnifiedLoginWrapper';

const LoginCallback = lazy(() => import('./LoginCallback'));
const OktaLogin = lazy(() => import('./OktaLogin'));
const Company = lazy(() => import('./Company'));
const Eula = lazy(() => import('./Eula'));

export default function Login(): ReactElement {
  const authenticated = useAppSelector((state) => isAuthenticated(state, true));
  const { pathname } = useLocation();
  const showEula = useAppSelector((state) => getCurrentUser(state)?.showEula);
  useEffect(() => {
    // Some browsers will wipe the session tokens (`FWI_COMPANY_ID` and
    // `FWI_COMPANY_NAME`) when the browser tab is closed while others will not.
    // If the auth tokens have not expired by the next time the user navigates
    // to Cloud, the auth tokens will be reused if a user logs in with the same
    // IDP even if it's a different email meaning you log in as a different
    // user.
    if (
      authenticated ||
      pathname === COMPANY ||
      pathname === LOGIN_CALLBACK ||
      isEula(pathname)
    ) {
      return;
    }

    removeTokenControllers();
    removeLastInteractionTime();
    removeAuthenticationCookies();
  }, [pathname, authenticated]);

  // Redirect only once the current company info has been loaded
  if (authenticated && showEula === false) {
    return <Redirect to={DASHBOARD} />;
  }

  const Wrapper = !isEula(pathname) ? UnifiedLoginWrapper : Fragment;

  return (
    <Wrapper>
      <Switch>
        <SecureRoute allowToken path={COMPANY} exact>
          <Company />
        </SecureRoute>
        <SecureRoute path={EULA} exact>
          <Eula />
        </SecureRoute>
        <Route path={LOGIN_CALLBACK} exact>
          <LoginCallback />
        </Route>
        <Route path={OKTA_LOGIN} exact>
          <OktaLogin />
        </Route>
        <Route path={LOGIN} exact>
          <EmailLogin />
        </Route>
      </Switch>
    </Wrapper>
  );
}
