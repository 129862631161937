import { createAction, createReducer } from '@reduxjs/toolkit';

import { CompanySettingsState, ReportingModuleStage } from 'appTypes';
import { fetchCompanySettings, putCompanySettings } from './api';

export const INITIAL_COMPANY_SETTINGS_STATE: CompanySettingsState = {
  loading: true,
  settings: null,
  reportingModuleStage: ReportingModuleStage.NotActivatedScreen,
};

export const changeReportingStage = createAction<ReportingModuleStage>(
  'companySettings/changeReportingStage'
);

export default createReducer(INITIAL_COMPANY_SETTINGS_STATE, (builder) =>
  builder
    .addCase(fetchCompanySettings.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchCompanySettings.fulfilled, (state, action) => {
      const isReportsEnabledForTheFirstTime =
        action.payload.isReportsEnabledForTheFirstTime;
      state.loading = false;
      state.settings = action.payload;
      state.reportingModuleStage = !isReportsEnabledForTheFirstTime
        ? ReportingModuleStage.NotActivatedScreen
        : ReportingModuleStage.Unavailable;
    })
    .addCase(fetchCompanySettings.rejected, (state) => {
      state.loading = false;
    })
    .addCase(putCompanySettings.fulfilled, (state, action) => {
      state.settings = action.payload;
    })
    .addCase(changeReportingStage, (state, action) => {
      state.reportingModuleStage = action.payload;
    })
);
