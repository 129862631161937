import { createReducer } from '@reduxjs/toolkit';

import { EulaState } from 'appTypes';
import { authenticated } from 'appState/auth';

import { acceptEula, fetchEulaContent } from './api';

export const INITIAL_EULA_STATE: EulaState = {
  error: null,
  loading: true,
  accepting: false,
  htmlContent: '',
  downloadUrl: '',
};

export default createReducer(INITIAL_EULA_STATE, (builder) =>
  builder
    .addCase(fetchEulaContent.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchEulaContent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.htmlContent = payload.htmlContent;
      state.downloadUrl = payload.downloadUrl;
    })
    .addCase(fetchEulaContent.rejected, (state) => {
      state.loading = false;
      state.error = 'fetch';
    })
    .addCase(acceptEula.pending, (state) => {
      state.accepting = true;
      state.error = null;
    })
    .addCase(acceptEula.fulfilled, (state) => {
      state.accepting = false;
    })
    .addCase(acceptEula.rejected, (state) => {
      state.accepting = false;
      state.error = 'accept';
    })
    .addCase(authenticated, (state, { payload }) => {
      // remove eula from cache once the login flow has completed
      return payload === 'done' ? INITIAL_EULA_STATE : state;
    })
);
