import { createReducer } from '@reduxjs/toolkit';

import { LabelsState } from 'appTypes';

import { deleteLabels, fetchLabel, fetchLabels, upsertLabel } from './api';
import { adapter } from './schema';

export const INITIAL_LABELS_STATE: LabelsState = adapter.getInitialState({
  loading: false,
  loadingIds: [],
  markedForDeletion: [],
});

export default createReducer(INITIAL_LABELS_STATE, (builder) =>
  builder
    .addCase(fetchLabels.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchLabels.fulfilled, (state, action) => {
      state.loading = false;
      adapter.addMany(state, action.payload.labels);
    })
    .addCase(fetchLabels.rejected, (state) => {
      state.loading = false;
    })
    .addCase(fetchLabel.pending, (state, action) => {
      state.loadingIds.push(action.meta.arg);
    })
    .addCase(fetchLabel.fulfilled, (state, action) => {
      const labelId = action.meta.arg;

      if (!state.markedForDeletion.includes(labelId)) {
        adapter.upsertOne(state, action.payload.labels[labelId]);
      }

      state.markedForDeletion = state.markedForDeletion.filter(
        (id) => id !== labelId
      );
      state.loadingIds = state.loadingIds.filter((id) => id !== labelId);
    })
    .addCase(fetchLabel.rejected, (state, action) => {
      const labelId = action.meta.arg;
      state.loadingIds = state.loadingIds.filter((id) => id !== labelId);
    })
    .addCase(upsertLabel.fulfilled, (state, action) => {
      // if there is no `id`, this is a CREATE action and we don't need to do anything else
      if (!action.meta.arg.id) {
        adapter.upsertMany(state, action.payload.labels);
        return;
      }

      // the PUT response for updates is a bit weird and will only return
      // `values` that were sent in the request instead of the fully updated
      // label so we have to manually apply the patch ourselves
      const { id, name, inputType, valueIdsToDelete } = action.meta.arg;
      const returnedLabel = action.payload.labels[id];
      const existingLabel = state.entities[id];
      if (!existingLabel || !returnedLabel) {
        return;
      }

      existingLabel.name = name;
      existingLabel.inputType = inputType;

      // need to ensure duplicates aren't added
      const values = new Set([
        ...existingLabel.values,
        ...returnedLabel.values,
      ]);
      if (valueIdsToDelete.length) {
        valueIdsToDelete.forEach((valueId) => {
          values.delete(valueId);
        });
      }

      existingLabel.values = [...values];
    })
    .addCase(deleteLabels.pending, (state, action) => {
      state.markedForDeletion = [...action.meta.arg];
    })
    .addCase(deleteLabels.fulfilled, (state, action) => {
      adapter.removeMany(state, action.payload.removedLabelIds);
    })
    .addCase(deleteLabels.rejected, (state, action) => {
      const labels = action.meta.arg;
      let markedForDeletion = [...state.markedForDeletion];

      for (const label of labels) {
        markedForDeletion = markedForDeletion.filter((id) => id !== label);
      }

      state.markedForDeletion = markedForDeletion;
    })
);
