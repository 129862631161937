import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
  deleteLabels,
  fetchLabel,
  fetchLabels,
  upsertLabel,
} from 'appState/labels/api';
import { LabelValuesState, NormalizedLabels } from 'appTypes';

import { adapter } from './schema';

export const INITIAL_LABEL_VALUES_STATE: LabelValuesState =
  adapter.getInitialState();

export default createReducer(INITIAL_LABEL_VALUES_STATE, (builder) =>
  builder
    .addCase(deleteLabels.fulfilled, (state, action) => {
      adapter.removeMany(state, action.payload.removedValueIds);
    })
    .addMatcher<PayloadAction<NormalizedLabels>>(
      (action) =>
        fetchLabels.fulfilled.match(action) ||
        fetchLabel.fulfilled.match(action) ||
        upsertLabel.fulfilled.match(action),
      (state, action) => {
        adapter.upsertMany(state, action.payload.labelValues);
      }
    )
);
