import { SYS_NAMES, SYS_NAME_KEYS } from 'fwi-constants';

import { ModulePermission } from 'fwi-fe-types';

import { ReadonlyEntityList } from 'appTypes';
import { AUTHOR, CONTRIBUTOR, SUPERUSER, VIEWER } from 'constants/licenseTypes';
import {
  COMPLETE_PERMISSIONS_MODULE_IDS,
  AUTHOR_PERMISSIONS,
  CONTRIBUTOR_PERMISSIONS,
  SUPERUSER_PERMISSIONS,
  VIEWER_PERMISSIONS,
} from 'constants/modules';
import {
  ALLOW_PERMISSIONS,
  DENY_PERMISSIONS,
  DENY,
  ALL_ITEMS,
} from 'constants/permissions';

import { isArrayEqual } from './arrays';

/*
 * Function for deciding the default permissions of a user based
 * on their license type and admin status.
 * @param licenseType - The license type of the user
 * @param isAdmin - Whether or not the user is an admin
 * @returns The default policy of the user
 */
export const getUserPermissions = (
  licenseType: string,
  isAdmin: boolean
): ModulePermission[] => {
  return COMPLETE_PERMISSIONS_MODULE_IDS.reduce<ModulePermission[]>(
    (permissions, moduleId) => {
      let isAllowed = false;

      switch (licenseType) {
        case CONTRIBUTOR:
          isAllowed = CONTRIBUTOR_PERMISSIONS.includes(moduleId);
          break;
        case SUPERUSER:
          isAllowed = SUPERUSER_PERMISSIONS.includes(moduleId);
          break;
        case VIEWER:
          isAllowed = VIEWER_PERMISSIONS.includes(moduleId);
          break;
        case AUTHOR:
          isAllowed = AUTHOR_PERMISSIONS.includes(moduleId);
          break;
      }

      let permission = isAllowed
        ? { ...ALLOW_PERMISSIONS, moduleId }
        : { ...DENY_PERMISSIONS, moduleId };

      if (
        (licenseType === AUTHOR || licenseType === VIEWER) &&
        !isAdmin &&
        ![
          SYS_NAME_KEYS.Community,
          SYS_NAME_KEYS.CloudSearch,
          SYS_NAME_KEYS.AnalyticsService,
          SYS_NAME_KEYS.Alerts,
        ].includes(moduleId)
      ) {
        permission = { ...DENY_PERMISSIONS, moduleId };
      }

      permissions.push(permission);
      return permissions;
    },
    []
  );
};

/*
 * Function for deciding the default permissions of a group
 * @returns The default permissions of the group
 */
export const getGroupPermissions = (): ModulePermission[] => {
  return COMPLETE_PERMISSIONS_MODULE_IDS.reduce(
    (permissions: ModulePermission[], moduleId: SYS_NAMES) => {
      let permission = { ...DENY_PERMISSIONS, moduleId };

      if (
        [
          SYS_NAME_KEYS.Community,
          SYS_NAME_KEYS.CloudSearch,
          SYS_NAME_KEYS.AnalyticsService,
          SYS_NAME_KEYS.Alerts,
        ].includes(moduleId)
      ) {
        permission = { ...ALLOW_PERMISSIONS, moduleId };
      }

      permissions.push(permission);
      return permissions;
    },
    []
  );
};

/**
 *  Compares two permissions to see if they are equal.
 *  This ignores `moduleId` since the idea is to verify if permissions are allow, deny, or selected.
 * @param firstPermission - a permission
 * @param secondPermission - a second permission to compare against the first
 * @returns true if two permissions are equal
 */

export const isPermissionEqual = (
  firstPermission?: ModulePermission | null,
  secondPermission?: ModulePermission | null
): boolean => {
  if (!firstPermission && !secondPermission) {
    return true;
  }

  if (
    (!firstPermission && secondPermission) ||
    (firstPermission && !secondPermission)
  ) {
    return false;
  }

  return firstPermission?.effect !== secondPermission?.effect ||
    !isArrayEqual(firstPermission?.actions, secondPermission?.actions) ||
    !isArrayEqual(firstPermission?.resources, secondPermission?.resources)
    ? false
    : true;
};

/**
 * Determines whether two sets of module permissions are equal
 * @param firstPermissions - a list of permissions
 * @param secondPermissions - a second list of permissions to compare against the first
 * @returns true if two sets of permissions are equal
 */
export const isPermissionsEqual = (
  firstPermissions?: ReadonlyEntityList<ModulePermission>,
  secondPermissions?: ReadonlyEntityList<ModulePermission>
): boolean => {
  if (
    (!firstPermissions && !secondPermissions) ||
    (!firstPermissions?.length && !secondPermissions?.length)
  ) {
    return true;
  }
  if (
    !firstPermissions ||
    !secondPermissions ||
    !firstPermissions.length ||
    !secondPermissions.length
  ) {
    return false;
  }

  const firstPermissionsMap = firstPermissions.reduce(
    (map: Record<string, ModulePermission>, perm: ModulePermission) => {
      map[perm.moduleId] = perm;
      return map;
    },
    {}
  );
  const secondPermissionsMap = secondPermissions.reduce(
    (map: Record<string, ModulePermission>, perm: ModulePermission) => {
      map[perm.moduleId] = perm;
      return map;
    },
    {}
  );
  const firstKeys = firstPermissions.length
    ? Object.keys(firstPermissionsMap)
    : [];
  const secondKeys = secondPermissions.length
    ? Object.keys(secondPermissionsMap)
    : [];
  if (!isArrayEqual(firstKeys, secondKeys)) {
    return false;
  }

  return firstKeys.some(
    (key) =>
      !isPermissionEqual(firstPermissionsMap[key], secondPermissionsMap[key])
  )
    ? false
    : true;
};

/**
 * Builds the missing permissions so that the permissions are fully listed
 * @param permissions - a list of permissions that is possibly incomplete
 * @returns a complete list of permissions
 */
export const buildCompletePermissions = (
  permissions?: ReadonlyEntityList<ModulePermission> | null
): ModulePermission[] => {
  // getGroupPermissions is always DENY for permissions which is what we need here
  if (!permissions || !permissions.length) {
    return getGroupPermissions();
  }

  return COMPLETE_PERMISSIONS_MODULE_IDS.reduce(
    (list: ModulePermission[], moduleId: SYS_NAMES) => {
      const currentPermission = permissions.find(
        (permission: ModulePermission) => permission.moduleId === moduleId
      );
      if (!currentPermission) {
        if (
          [
            SYS_NAME_KEYS.Community,
            SYS_NAME_KEYS.CloudSearch,
            SYS_NAME_KEYS.AnalyticsService,
            SYS_NAME_KEYS.Alerts,
          ].includes(moduleId)
        ) {
          list.push({ ...ALLOW_PERMISSIONS, moduleId });
        } else {
          list.push({ ...DENY_PERMISSIONS, moduleId });
        }
      } else {
        list.push(currentPermission);
      }

      return list;
    },
    []
  );
};

/**
 * This function returns true if a permission should really be considered "limited"
 * @param permission - the permission object to judge
 * @returns true if a permission is truly limited
 */
export const isPermissionLimited = (permission: ModulePermission): boolean => {
  if (permission.effect === DENY) {
    return false;
  }

  return permission.resources.length && permission.resources.includes(ALL_ITEMS)
    ? false
    : true;
};
