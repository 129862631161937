import { PaginationRequestOptions } from 'fwi-fe-types';
import { authTokenController, getCompanyId, StatusCodes } from 'fwi-fe-utils';
import { IStringifyOptions, stringify } from 'qs';

import { logout } from './logout';

/**
 * This is a small util to convert an "endpoint string" into a full string by
 * replacing url params correctly. The `companyId` will always be replaced in
 * the url string by the `companyId` cookie.
 *
 * @example
 * Simple example
 * ```ts
 * const endpoint = format('/some-route/:param/other/:companyId', {
 *   param: 'some-guid',
 * });
 *
 * expect(endpoint).toBe('/some-route/some-guid/other/company-id');
 * ```
 *
 * @param endpoint - The endpoint to update
 * @param params - An object of key/value pairs to replace
 * @returns the updated endpoint string
 */
export function format(
  endpoint: string,
  params?: Record<string, string>
): string {
  const result = Object.entries({
    companyId: getCompanyId(),
    ...params,
  }).reduce(
    (updated, [name, value]) => updated.replace(`:${name}`, value),
    endpoint
  );

  // sanity check in dev that we always provide valid url params
  if (process.env.NODE_ENV !== 'production') {
    const matches = result.match(/:[A-z0-9]+/g);
    if (matches) {
      throw new Error(
        `Invalid API url: '${result}'.
Missing the following parameters: '${matches.join(', ')}'`
      );
    }
  }

  return result;
}

export interface BaseApiOptions extends RequestInit {
  /**
   * Any url params to use for the endpoint string.
   *
   * @see {@link format}
   */
  params?: Record<string, string>;
}

export interface APIOptions extends BaseApiOptions {
  /**
   * Boolean if the browser should infer the `Content-Type` instead of setting
   * it to `application/json`. This should be enabled for posting FormData or
   * files.
   */
  inferType?: boolean;

  /**
   * Boolean if the `status` should be checked for permissions errors before
   * returning the `response`. This should normally be enabled by default so
   * that the user will automatically be logged out if they have an expired
   * access token or display an error overlay if they don't have access to the
   * API.
   *
   * This should really just be set to `false` if the item is linked to another
   * entity and want to display a message to the user that they don't have
   * access to the item.
   */
  checkStatus?: boolean;
}

/**
  This function handles api calls using `fetch` along with `createAsyncThunk`
  from `@reduxjs/toolkit`.
 *
 * ```ts
 * import { createAsyncThunk } from '@reduxjs/toolkit';
 * import { EntityId } from 'fwi-fe-types';
 *
 * import { TABLES_ID_ENDPOINT } from 'constants/endpoints';
 * import { api } from 'utils/api';
 *
 * // pretend real endpoint
 * const TABLE_ENDPOINT = '';
 * export const fetchTableById = createAsyncThunk<TableEntity, EntityId>(
 *   'fetchTableById',
 *   async (tableId) => {
 *     const response = await api(TABLES_ID_ENDPOINT, {
 *       params: { tableId }
 *     });
 *     const json: TableEntity = await response.json();
 *     // can/should normalize/validate
 *
 *     return json;
 *   }
 * );
 * ```
 *
 * Handling errors example:
 *
 * ```ts
 * import { createAsyncThunk } from '@reduxjs/toolkit';
 * import { EntityId } from 'fwi-fe-types';
 *
 * import { api } from 'utils/api';
 *
 * export const bulkUploadDevices = createAsyncThunk<
 *   BulkUploadJSONResponse,
 *   File,
 *   AppThunkConfig<BulkUploadJSONResponse>
 * >(
 *   'bulkUploadDevices',
 *   async (file, { dispatch, getState, rejectWithValue }) => {
 *     const folderId = getFolderIdFromState(getState());
 *     const body = new FormData();
 *     body.append('clientId', getClientId());
 *     body.append('file', file, file.name);
 *     body.append('parentFolderId', folderId);
 *
 *     const response = await api(DEVICES_ENDPOINT, {
 *       dispatch,
 *       method: 'POST',
 *       body,
 *       inferType: true,
 *     });
 *
 *     const json: BulkUploadJSONResponse = await response.json();
 *     if (!response.ok) {
 *       return rejectWithValue(json);
 *     }
 *
 *     return json;
 *   }
 * );
 * ```
 *
 * Note: You will manually need to call `JSON.stringify(data)` when sending json
 * since it is no longer done automatically.
 *
 * Note: The downside to the `createAsyncThunk` function is that it only ever
 * allows one argument which means you'll need to use an object instead of
 * multiple arguments.
 *
 * @see https://redux-toolkit.js.org/api/createAsyncThunk
 * @param endpoint The API endpoint to use
 * @param options All the API options to use along with the `dispatch` function
 * to handle errors.
 * @return the response object which can be used to get json, text, etc
 * @throws error if the API responds with a 401 status code and the
 * `checkStatus` option is omitted or `true`
 */
export async function api(
  endpoint: string,
  {
    headers: providedHeaders,
    inferType = false,
    checkStatus = true,
    params,
    ...options
  }: APIOptions = {}
): Promise<Response> {
  const headers = new Headers(providedHeaders);
  if (!headers.has('Authorization')) {
    const accessToken = await authTokenController.getAccessToken();
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  if (!inferType && !headers.has('Content-Type')) {
    headers.set('Content-Type', 'application/json');
  }

  const response = await fetch(format(endpoint, params), {
    ...options,
    headers,
  });

  if (!checkStatus) {
    return response;
  }

  const { status } = response;
  if (status === StatusCodes.UNAUTHORIZED) {
    logout('SessionUnauthorized');
  }

  return response;
}

/**
 * This is a simple wrapper for downloading a file through an API call that
 * defaults to `text/csv`. This can be used with the `createAsyncThunk` from
 * `@reduxjs/toolkit` if pending, fulfilled, and rejected actions are needed,
 * but it seems that isn't required for most use cases. Simple example:
 *
 * ```ts
 * export function downloadTable(
 *   tableId: EntityId,
 *   name: string,
 *   location: AnalyticsLocation
 * ): AppThunk {
 *   return (dispatch) => {
 *     const analytics = createTableAnalyticsAction(TABLE_DOWNLOADED, location);
 *     if (analytics) {
 *       dispatch(analytics);
 *     }
 *
 *     const fileName = `${name}${isCsvFile(name) ? '' : '.csv'}`
 *     apiDownload(`${TABLE_ENDPOINT}/${tableId}`, fileName)
 *       .catch(() => dispatch(addToast({ id: 'DownloadFailed' })))
 *   }
 * };
 * ```
 *
 * Note: This function will throw an error if the response is not ok (> 200), so
 * you'll want to use `.catch(() => { handle error })`.
 *
 * @param endpoint The endpoint to download a file from
 * @param fileName The filename to use for the downloaded file
 * @param options Any options to provide to the `fetch` call. The `headers`
 * default to using `Accept: 'text/csv'` so if you need to download a different
 * file type the `headers` option will need to changed when using this function.
 */
export async function apiDownload(
  endpoint: string,
  fileName: string,
  { headers: providedHeaders, params, ...options }: BaseApiOptions = {}
): Promise<void> {
  const headers = new Headers(providedHeaders);
  if (!headers.has('Authorization')) {
    const accessToken = await authTokenController.getAccessToken();
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  if (!headers.has('Accept')) {
    headers.set('Accept', 'text/csv');
  }

  const response = await fetch(format(endpoint, params), {
    ...options,
    headers,
  });
  if (!response.ok) {
    throw new Error();
  }

  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.download = fileName;
  a.href = blobUrl;
  a.click();
  window.URL.revokeObjectURL(blobUrl);
}

/**
 * This is a simple wrapper for the {@link stringify} function that handles the
 * sort/sortOrder behavior for APIs that paginate with query strings instead of
 * POST body.
 *
 * @param options - The sort options to convert into a query string
 * @param stringifyOptions - An optional object of {@link IStringifyOptions} to
 * format the string
 * @returns a querystring with the sort options that defaults to including a
 * leading `?`
 */
export function createSortQuerystring<O extends PaginationRequestOptions>(
  options: O,
  stringifyOptions: IStringifyOptions = {}
): string {
  const { sort, sortOrder, ...others } = options;
  const {
    addQueryPrefix = true,
    arrayFormat = 'repeat',
    ...qsOpts
  } = stringifyOptions;

  return stringify(
    {
      sort: `${sort} ${sortOrder}`,
      ...others,
    },
    {
      addQueryPrefix,
      arrayFormat,
      ...qsOpts,
    }
  );
}
