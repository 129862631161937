import { EntityId } from 'fwi-fe-types';

import { getListViewCustomerById } from 'appState/customers/selectors';
import { getListViewGroupById } from 'appState/groups/selectors';
import { getListViewLabelById } from 'appState/labels/selectors';
import { getListViewUserById } from 'appState/users/selectors';
import {
  AppState,
  ReadonlyEntityList,
  SelectionsState,
  ListViewEntity,
} from 'appTypes';

const getSelections = (state: AppState): SelectionsState => state.selections;

/**
 * @returns all the selected entity ids
 */
export const getSelectedEntityIds = (state: AppState): readonly EntityId[] =>
  getSelections(state).entities;

/**
 *
 * @returns only the first selected entity id from the
 * {@link SelectionsState.entities} when using single select behavior.
 */
export const getSelectedEntityId = (state: AppState): EntityId =>
  getSelectedEntityIds(state)[0] ?? '';

/**
 *
 * @param state - The top-level store state
 * @param entityId - The entity's id to get
 * @returns an entity or undefined
 */
export const getStandardizedEntityById = (
  state: AppState,
  entityId: EntityId
): Readonly<ListViewEntity> | undefined =>
  getListViewUserById(state, entityId) ||
  getListViewGroupById(state, entityId) ||
  getListViewCustomerById(state, entityId) ||
  getListViewLabelById(state, entityId);

/**
 *
 * @param state - The top-level store state
 * @param entityIds - The list of entity ids to get
 * @returns an list of entities that have been found by id.
 */
export const getStandardizedEntitiesByIds = (
  state: AppState,
  entityIds: readonly EntityId[]
): ReadonlyEntityList<ListViewEntity> =>
  entityIds.reduce<ListViewEntity[]>((list, entityId) => {
    const entity = getStandardizedEntityById(state, entityId);

    if (entity) {
      list.push(entity);
    }

    return list;
  }, []);

/**
 *
 * @param state - The top-level store state
 * @returns an list of entities that have been found by id.
 */
export const getSelectedEntities = (
  state: AppState
): ReadonlyEntityList<ListViewEntity> =>
  getStandardizedEntitiesByIds(state, getSelectedEntityIds(state));
