import { createReducer } from '@reduxjs/toolkit';
import { GroupEntity } from 'fwi-fe-types';

import {
  GROUPS_INITIAL_STATE,
  INITIAL_OFFSET_STATE,
  INITIAL_LOADING_STATE,
  FILTER,
} from 'constants/pagination';
import { fetchGroups } from 'appState/groups';

export default createReducer(GROUPS_INITIAL_STATE, (builder) => {
  builder.addCase(fetchGroups.pending, (state, action) => {
    if (action.meta.arg.paginationLocation !== FILTER) {
      return;
    }
    state.loading = !INITIAL_LOADING_STATE;
  });

  builder.addCase(fetchGroups.fulfilled, (state, action) => {
    if (action.meta.arg.paginationLocation !== FILTER) {
      return;
    }

    const { payload } = action;
    const { numberOfItems, items, offset, sort, sortOrder } = payload;

    state.offset = offset || INITIAL_OFFSET_STATE;
    state.loading = INITIAL_LOADING_STATE;

    state.numberOfItems = numberOfItems;

    // itemList changes for a general fetch
    if (state.offset === INITIAL_OFFSET_STATE) {
      state.itemList = items.map((group: GroupEntity) => group.id);
    } else {
      const itemsToAdd = items.reduce((list: string[], group: GroupEntity) => {
        const { id } = group;
        if (!state.itemList.includes(id)) {
          list.push(id);
        }
        return list;
      }, []);

      state.itemList = [...state.itemList, ...itemsToAdd];
    }

    const sortState = {
      sort: sort || state.sort.sort,
      sortOrder: sortOrder || state.sort.sortOrder,
      isSorted: offset === 0 || state.sort.isSorted,
    };
    state.sort = sortState;
  });

  builder.addCase(fetchGroups.rejected, (state, action) => {
    if (action.meta.arg.paginationLocation !== FILTER) {
      return;
    }
    state.loading = INITIAL_LOADING_STATE;
  });
});
