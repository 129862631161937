import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'fwi-fe-utils';
import {
  ChannelEntity,
  EntityId,
  PaginationRequestOptions,
} from 'fwi-fe-types';

import {
  APIRejectionThunkConfig,
  NormalizedChannelEntity,
  NormalizedChannels,
} from 'appTypes';
import { CHANNELS_ENDPOINT, CHANNEL_ID_ENDPOINT } from 'constants/endpoints';
import { api, createSortQuerystring } from 'utils/api';

import { normalizeChannel, normalizeChannels } from './schema';

interface ChannelsResponseJson {
  items: ChannelEntity[];
  size: number;
  numberOfItems: number;
  sort: string;
}

/**
 * Fetches channels for pagination.
 *
 * @returns The {@link NormalizedChannels} record.
 */
export const fetchChannels = createAsyncThunk<
  NormalizedChannels & { total: number },
  PaginationRequestOptions<keyof NormalizedChannelEntity> | void,
  APIRejectionThunkConfig
>('channels/fetchChannels', async (options, { rejectWithValue }) => {
  let query = '';
  if (options) {
    query = createSortQuerystring(options);
  }

  const response = await api(`${CHANNELS_ENDPOINT}${query}`);
  if (!response.ok) {
    const { status } = response;
    return rejectWithValue({
      status,
      unmodified: status === StatusCodes.NOT_MODIFIED,
    });
  }

  const { items, numberOfItems }: ChannelsResponseJson = await response.json();
  return {
    ...normalizeChannels(items),
    total: numberOfItems,
  };
});

/**
 * Fetches a channel by id
 *
 * @param channelId - The channel's id to fetch
 * @returns The {@link NormalizedChannels} record.
 */
export const fetchChannel = createAsyncThunk<
  NormalizedChannels,
  EntityId,
  APIRejectionThunkConfig
>('channels/fetchById', async (channelId, { rejectWithValue }) => {
  const response = await api(CHANNEL_ID_ENDPOINT, {
    params: { channelId },
  });

  if (!response.ok) {
    const { status } = response;
    return rejectWithValue({
      status,
      unmodified: status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json = await response.json();
  return normalizeChannel(json);
});
