import { combineReducers } from 'redux';

import { PaginationState } from 'appTypes';
import homeUsers from './homeUsers';
import homeGroups from './homeGroups';
import homeLabels from './homeLabels';
import groupModalUsers from './groupModalUsers';
import userModalGroups from './userModalGroups';
import filterGroups from './filterGroups';

export default combineReducers<PaginationState>({
  homeUsers,
  homeGroups,
  homeLabels,
  groupModalUsers,
  userModalGroups,
  filterGroups,
});
