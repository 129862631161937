import {
  DeviceFolderEntity,
  EntityId,
  FolderEntity,
  FolderType,
  LibraryFolderEntity,
} from 'fwi-fe-types';

import {
  ReadonlyEntityRecord,
  ReadonlyEntityList,
  RESTRICTABLE_MODULE_ACCESS_NAME,
  Folder,
} from 'appTypes';
import {
  DEVICES as DEVICES_ROUTE,
  LIBRARY as LIBRARY_ROUTE,
  CHANNELS as CHANNELS_ROUTE,
} from './routes';
import {
  CHANNELS_ROOT_FOLDER_NAME,
  DEVICES_ROOT_FOLDER_NAME,
  LIBRARY_ROOT_FOLDER_NAME,
} from 'constants/folders';

const { CONTENT_LIBRARY, DEVICE_MANAGEMENT, CHANNELS } =
  RESTRICTABLE_MODULE_ACCESS_NAME;

/**
 * Checks if the provided folder is a root folder. This also will typecast the
 * folder when it matches to be the `DeviceFolderEntity` or
 * `LibraryFolderEntity` if the `folderType` is provided.
 *
 * @param folder The folder to check against
 * @param folderType An optional folder type to also compare against
 * @param shared Boolean if the root library folder should be the shared content
 * folder.
 * @returns true if the folder is a root folder.
 */
export function isRootFolder(
  folder: Folder,
  folderType: typeof FolderType.DEVICE
): folder is Readonly<DeviceFolderEntity>;
export function isRootFolder(
  folder: Folder,
  folderType: typeof FolderType.LIBRARY,
  shared?: boolean
): folder is Readonly<LibraryFolderEntity>;
export function isRootFolder(
  folder: Folder,
  folderType?: FolderType,
  shared?: boolean
): folder is Readonly<FolderEntity>;
export function isRootFolder(
  folder: Folder,
  folderType?: FolderType,
  shared = false
): folder is Readonly<FolderEntity> {
  return (
    folder?.parentFolderId === null &&
    (!folderType || folder.folderType === folderType) &&
    (folder.folderType !== FolderType.LIBRARY || !!folder.shared === shared)
  );
}

/**
 *
 * @param folder The folder to check against
 * @param folderType An optional folder type to also compare against
 * @returns true if the folder is the virtual root folder
 */
export const isVirtualRootFolder = (
  folder: Readonly<FolderEntity> | null | undefined,
  folderType?: FolderType
): folder is FolderEntity =>
  isRootFolder(folder, folderType) && !!folder.virtual;

interface FoldersFromReturnValue {
  /**
   * The list of folders starting from the provided `folderId` to the root
   * folder.
   */
  folders: ReadonlyEntityList<FolderEntity>;

  /**
   * Boolean if the user does not have access to one of the parent folders.
   */
  isAccessDenied: boolean;
}

/**
 * This will get all the folders from the provided folder id (also included the
 * provided folder) up to the root (including root).
 *
 * @param folders The current folders redux state to extract folders from.
 * @param folderId the folder id to start the search from
 * @param excludeCurrent Boolean if the current folder (the `folderId` provided)
 * should not be included in the resulting list.
 * @returns The {@Link FoldersFromReturnValue}
 */
export const getFoldersFrom = (
  folders: ReadonlyEntityRecord<FolderEntity>,
  folderId: EntityId,
  excludeCurrent = false
): FoldersFromReturnValue => {
  let isVirtual = false;
  let isAccessDenied = true;
  const list: FolderEntity[] = [];
  let currentId = folderId;

  while (currentId) {
    const folder = folders[currentId];
    if (folder) {
      currentId = folder.parentFolderId || '';

      if (!excludeCurrent || folder.folderId !== folderId) {
        list.push(folder);
      }

      if (isRootFolder(folder)) {
        isAccessDenied = false;
        isVirtual = folder.virtual || false;
      }
    } else {
      currentId = '';
    }
  }

  isAccessDenied = isAccessDenied || (isVirtual && list.length === 1);

  return {
    folders: list,
    isAccessDenied,
  };
};

/**
 *
 * @param folders The current folders redux state to extract folders from.
 * @param folderId the folder id to start the search from
 * @param excludeCurrent Boolean if the current folder (the `folderId` provided)
 * should not be included in the resulting list.
 * @returns a list of all the folder ids up to the root
 */
export const getFolderIdsFrom = (
  folders: ReadonlyEntityRecord<FolderEntity>,
  folderId: EntityId,
  excludeCurrent = false
): readonly EntityId[] =>
  getFoldersFrom(folders, folderId, excludeCurrent).folders.map(
    ({ folderId }) => folderId
  );

/**
 * Get all the child folders within a folder. This can be updated to recursively
 * get all the child folders as well if it should traverse the entire folder tree.
 *
 * @param folders The folders state from redux or a list of folders.
 * @param folderId The folder id to get children for.
 * @param recursive Boolean if it should recursively get all the child folders
 * or just stop at the direct descendants
 * @return a list of the child folders.
 */
export const getChildFolders = (
  folders:
    | ReadonlyEntityRecord<FolderEntity>
    | ReadonlyEntityList<FolderEntity>,
  folderId: EntityId | null,
  recursive = false
): readonly FolderEntity[] => {
  const list: FolderEntity[] = Array.isArray(folders)
    ? folders
    : Object.values(folders);

  return list.reduce<FolderEntity[]>((collection, folder) => {
    if (folder.parentFolderId !== folderId) {
      return collection;
    }

    const trailing = recursive
      ? getChildFolders(list, folder.folderId, true)
      : [];
    return [...collection, folder, ...trailing];
  }, []);
};

/**
 *
 * @param folders All the folders to look through
 * @param folderType An optional folder type to enforce
 * @param shared An optional boolean if the folder should be the root shared
 * folder from library. This requires setting the `folderType` to
 * `FolderType.LIBRARY`.
 * @returns the root folder id or an empty string
 */
export const getRootFolderId = (
  folders: ReadonlyEntityRecord<FolderEntity>,
  folderType?: FolderType,
  shared = false
): EntityId => {
  const list = Object.values(folders);
  for (const folder of list) {
    if (isRootFolder(folder, folderType, shared)) {
      return folder.folderId;
    }
  }

  return '';
};

/**
 * Returns an object containing module, route, and name values for a given folder type
 * @param {FolderType} folderType - The type of folder to get values for
 * @returns {{module: string, route: string, name: string } | undefined} - The values for the folder type
 */
const getFolderTypeValue = (
  folderType: FolderType
): { module: string; route: string; name: string } => {
  switch (folderType) {
    case FolderType.LIBRARY:
      return {
        module: CONTENT_LIBRARY,
        route: LIBRARY_ROUTE,
        name: LIBRARY_ROOT_FOLDER_NAME,
      };
    case FolderType.DEVICE:
      return {
        module: DEVICE_MANAGEMENT,
        route: DEVICES_ROUTE,
        name: DEVICES_ROOT_FOLDER_NAME,
      };
    case FolderType.CHANNEL:
      return {
        module: CHANNELS,
        route: CHANNELS_ROUTE,
        name: CHANNELS_ROOT_FOLDER_NAME,
      };
  }
};

/**
 * Returns the corresponding module value for a given folder type
 * @param {FolderType} folderType - The type of folder to get the module for
 * @returns {string | undefined} - The module value
 */
export const getModuleByFolderType = (
  folderType: FolderType
): string | undefined => {
  return getFolderTypeValue(folderType).module;
};

/**
 * Returns the corresponding route value for a given folder type
 * @param {FolderType} folderType - The type of folder to get the route for
 * @returns {string | undefined} - The route value
 */
export const getRouteByFolderType = (
  folderType: FolderType
): string | undefined => {
  return getFolderTypeValue(folderType).route;
};

/**
 * Returns the corresponding folder name for a given folder type
 * @param {FolderType} folderType - The type of folder to get the name for
 * @returns {string | undefined} - The folder name
 */
export const getFolderNameByFolderType = (
  folderType: FolderType
): string | undefined => {
  return getFolderTypeValue(folderType).name;
};
