import { ReactElement, useState } from 'react';
import cn from 'classnames';
import { DropdownMenu, MenuItemSeparator, useIntl } from 'fwi-fe-components';

import { isMobile, useAppSelector } from 'appState';
import FormattedMessage from 'components/FormattedMessage';

import styles from './AccountOptions.module.scss';
import Companies from './Companies';
import DashboardMenuItem from './DashboardMenuItem';
import LogoutMenuItem from './LogoutMenuItem';
import NoneRoleItem from 'components/NoneRoleItem';
import UserSettingsDialog from './UserSettingsDialog';
import UserSettingsMenuItem from './UserSettingsMenuItem';
import { MyHarmony } from './MyHarmony';

const id = 'account-dropdown';
const logoutId = `${id}-logout`;
const settingsId = `${id}-settings`;
const dashboardId = `${id}-dashboard`;
const accountsId = `${id}-accounts`;
const accountIdPrefix = `${id}-account`;
const loadingId = `${accountsId}-loading`;

export default function AccountOptions(): ReactElement {
  const intl = useIntl();
  const mobile = useAppSelector(isMobile);
  const [settingsVisible, setSettingsVisible] = useState(false);

  return (
    <>
      <DropdownMenu
        id={id}
        aria-label={intl.formatMessage({ id: 'AccountOptions' })}
        buttonChildren={<MyHarmony mobile={mobile} />}
        className={cn(styles.container, {
          [styles.containerMobile]: mobile,
        })}
        buttonClassName={cn(styles.button, !mobile && styles.buttonDesktop)}
        menuWidth={280}
        isSheet={mobile}
      >
        <DashboardMenuItem id={dashboardId} />
        <UserSettingsMenuItem
          id={settingsId}
          onClick={() => setSettingsVisible(true)}
        />
        <LogoutMenuItem id={logoutId} />
        <MenuItemSeparator />
        <NoneRoleItem subheader id={accountsId}>
          <FormattedMessage messageId="Accounts" />
        </NoneRoleItem>
        <Companies loadingId={loadingId} accountIdPrefix={accountIdPrefix} />
      </DropdownMenu>
      <UserSettingsDialog
        visible={settingsVisible}
        onRequestClose={() => setSettingsVisible(false)}
      />
    </>
  );
}
