import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'fwi-fe-utils';
import { EntityId } from 'fwi-fe-types';

import { APIRejectionThunkConfig } from 'appTypes';
import { DRIVES_ENDPOINT } from 'constants/endpoints';
import { api } from 'utils/api';

import { getDriveId, isLoadingDriveId } from './selectors';

/**
 * Fetches the drive id for the current company.
 *
 * Note: This will not trigger the API call if:
 * - the driveId is already stored in state
 * - the API call is already loading
 *
 * @returns the drive id for the current company
 */
export const fetchDriveId = createAsyncThunk<
  EntityId,
  void,
  APIRejectionThunkConfig
>(
  'drive/fetchId',
  async (_, { rejectWithValue }) => {
    const response = await api(DRIVES_ENDPOINT);
    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    const { driveId } = await response.json();
    return driveId;
  },
  {
    condition(_, { getState }) {
      const state = getState();
      return !isLoadingDriveId(state) || !!getDriveId(state);
    },
  }
);
