import { PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE, push, RouterState } from 'connected-react-router';
import {
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { addToast } from 'appState/toasts';
import { CustomerEntity, CustomerStatus } from 'appTypes';
import {
  CUSTOMERS,
  CUSTOMERS_ACTIVE,
  CUSTOMERS_FAILED,
  CUSTOMERS_PENDING,
} from 'utils/routes';

import {
  activateCustomer,
  deactivateCustomer,
  deleteCustomer,
  fetchCustomerCount,
  suspendCustomer,
} from './api';
import {
  getCustomerById,
  getFailedCustomerCount,
  getPendingCustomerCount,
} from './selectors';

type TakeAction = ReturnType<
  | typeof activateCustomer.pending
  | typeof deactivateCustomer.pending
  | typeof deleteCustomer.pending
>;

function generateRedirectFlowHandler(
  request:
    | typeof activateCustomer.pending.type
    | typeof deactivateCustomer.pending.type
    | typeof deleteCustomer.pending.type,
  success: string,
  failure: string,
  toastMessageId: string
) {
  return function* handler() {
    yield takeEvery(request, function* execute(action: TakeAction) {
      const customer: CustomerEntity | undefined = yield select((state) =>
        getCustomerById(state, action.meta.arg)
      );

      // redirect back to the list view since it might've been triggered from
      // kebab menu in super modal
      const mode = customer?.status ?? CustomerStatus.ACTIVE;
      yield put(push(`${CUSTOMERS}/${mode}`));

      // Wait for customer delete request to finish
      const { opFailure } = yield race({
        opSuccess: take(success),
        opFailure: take(failure),
      });

      // Show a toast message if op failed
      if (opFailure) {
        yield put(addToast({ messageId: toastMessageId }));
      } else {
        yield put(fetchCustomerCount());
      }
    });
  };
}

function* manageSpecialRoutes() {
  yield takeLatest(
    LOCATION_CHANGE,
    function* execute(action: PayloadAction<RouterState>) {
      // Check if user is in the special route
      const { pathname } = action.payload.location;
      const isPendingRoute = pathname.startsWith(CUSTOMERS_PENDING);
      const isFailedRoute = pathname.startsWith(CUSTOMERS_FAILED);
      if (!isPendingRoute && !isFailedRoute) {
        return;
      }

      // Wait for get customers count request to be completed
      const { failure } = yield race({
        success: take(fetchCustomerCount.fulfilled.type),
        failure: take(fetchCustomerCount.rejected.type),
      });
      if (failure) {
        return;
      }

      const pending: number = yield select(getPendingCustomerCount);
      const failed: number = yield select(getFailedCustomerCount);
      const redirectAction = push(CUSTOMERS_ACTIVE);

      // Redirect to the active view if there are no customers in the special routes
      if ((isPendingRoute && !pending) || (isFailedRoute && !failed)) {
        yield put(redirectAction);
      }
    }
  );
}

export function* customersSagas() {
  yield fork(
    generateRedirectFlowHandler(
      deleteCustomer.pending.type,
      deleteCustomer.fulfilled.type,
      deleteCustomer.rejected.type,
      'CustomerDeleteFailed'
    )
  );
  yield fork(
    generateRedirectFlowHandler(
      activateCustomer.pending.type,
      activateCustomer.fulfilled.type,
      activateCustomer.rejected.type,
      'CustomerActivateFailed'
    )
  );
  yield fork(
    generateRedirectFlowHandler(
      suspendCustomer.pending.type,
      suspendCustomer.fulfilled.type,
      suspendCustomer.rejected.type,
      'CustomerSuspendFailed'
    )
  );
  yield fork(
    generateRedirectFlowHandler(
      deactivateCustomer.pending.type,
      deactivateCustomer.fulfilled.type,
      deactivateCustomer.rejected.type,
      'CustomerDeactivateFailed'
    )
  );
  yield fork(manageSpecialRoutes);
}
