import { HTMLAttributes, ReactElement, ReactNode } from 'react';
import cn from 'classnames';

import styles from './AppTitle.module.scss';

export interface AppTitleProps extends HTMLAttributes<HTMLHeadingElement> {
  offset?: boolean;
  children: ReactNode;
}

export default function AppTitle({
  className,
  children,
  offset = false,
  ...props
}: AppTitleProps): ReactElement {
  return (
    <h2
      {...props}
      id="main-title"
      className={cn(
        styles.title,
        {
          [styles.titleOffset]: offset,
        },
        className
      )}
    >
      {children}
    </h2>
  );
}
