import { TagDescription } from '@reduxjs/toolkit/dist/query';

import { AppDispatch } from 'appTypes';
import { groupsApi } from './query';

export const invalidateTags = ({
  dispatch,
  tags,
}: {
  dispatch: AppDispatch;
  tags: TagDescription<'Groups' | 'Users'>[];
}): void => {
  dispatch(groupsApi.util.invalidateTags(tags));
};
