import { StatusCodes } from 'fwi-fe-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIRejectionThunkConfig, LicenseQuotas } from 'appTypes';
import { COMPANY_QUOTAS_ENDPOINT } from 'constants/endpoints';
import { api } from 'utils/api';

import { isLoadingLicenseQuotas } from './selectors';

/**
 * Fetches the license quotas for the current company
 */
export const fetchLicenseQuotas = createAsyncThunk<
  LicenseQuotas,
  void,
  APIRejectionThunkConfig
>(
  'licenseQuotas/fetch',
  async (_, { rejectWithValue }) => {
    const response = await api(COMPANY_QUOTAS_ENDPOINT);
    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    return await response.json();
  },
  {
    condition(_arg, { getState }) {
      return !isLoadingLicenseQuotas(getState());
    },
  }
);
