import { createAction } from '@reduxjs/toolkit';

import { AnalyticsEvent, SalesforceAccountNumber, UserTraits } from 'appTypes';

/**
 * An analytics event used to identify the user based on the {@link UserTraits}.
 *
 * @param traits - The {@link UserTraits} from the current user.
 */
export const identifyUser = createAction<UserTraits>('analytics/identifyUser');

/**
 * An analytics event used to identify a current customer by the salesforce
 * account number.
 *
 * @param account - The {@link SalesforceAccountNumber} for the current user's
 * company.
 */
export const identifyCustomer = createAction<SalesforceAccountNumber>(
  'analytics/identifyCustomer'
);

/**
 * This action should be fired once the segment analytics have loaded.
 *
 * Note: This action will never fire if:
 * - the user has disabled analytics from their user preferences
 * - the user uses an adblocker like {@link https://github.com/gorhill/uBlock | uBlock Origin}
 * - the user uses a {@link https://pi-hole.net/ | Network-wide Ad Blocker}
 */
export const analyticsLoaded = createAction('analytics/loaded');

/**
 * This action is used to fire custom analytics events for FWI and should never
 * really be imported into sagas or components. Instead, check out the examples
 * below for usage:
 *
 * @example
 * Simple Binding
 * ```ts
 * // src/appState/analytics/{{CATEGORY}}.ts
 * import { analyticsTrackEvent } from './actions';
 *
 * const category = 'CATEGORY';
 *
 * export const someEventName = analyticsTrackEvent.bind(null, {
 *   eventName: 'My Event Name',
 *   properties: {
 *     category,
 *     label: 'Some Custom Label',
 *   },
 * });
 * ```
 *
 * @example
 * Requires an argument
 * ```ts
 * // src/appState/analytics/{{CATEGORY}}.ts
 * import { PayloadAction } from '@reduxjs/toolkit'
 *
 * import { AnalyticsEvent } from 'appTypes'
 *
 * import { analyticsTrackEvent } from './actions';
 *
 * const category = 'CATEGORY';
 *
 * export const someEventName = (information: string): PayloadAction<AnalyticsEvent> =>
 *   analyticsTrackEvent({
 *     eventName: 'My Event Name',
 *     properties: {
 *       category,
 *       label: `${information} Clicked`,
 *     }
 *   });
 * ```
 *
 * @param event - The {@link AnalyticsEvent} information
 */
export const analyticsTrackEvent =
  createAction<AnalyticsEvent>('analytics/track');
