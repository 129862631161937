/* eslint-disable no-redeclare */
import { EntityState } from '@reduxjs/toolkit';
import {
  EntityId,
  EntityMetadata,
  ISOTimestamp,
  SortState,
  ValuesOf,
} from 'fwi-fe-types';

import { LicenseType } from './admin';
import { LegacySearchState } from './legacySearch';

/**
 * Customers use bitwise flags instead of booleans for some reason.
 */
export const CustomerFlag = {
  Disabled: 0,
  Enabled: 1,
} as const;
export type CustomerFlag = ValuesOf<typeof CustomerFlag>;

/**
 * Options for customer status, also referred to as 'mode'.
 */
export const CustomerStatus = {
  PENDING: 'pending',
  FAILED: 'failed',
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  DEACTIVATED: 'deactivated',
  DELETED: 'deleted',
  TRIAL: 'trial',
  INTERNAL: 'internal',
} as const;
export type CustomerStatus = ValuesOf<typeof CustomerStatus>;

export type CustomerEntitlements = Record<EntityId, CustomerFlag>;

export interface CustomersRouteParmas {
  mode: CustomerStatus;
}

export interface CustomerAdmin {
  email: string;
  firstName: string;
  lastName: string;
}

export interface CustomerUsage {
  currentDriveSize: number;
  devicesUsed: number;
  iFramesUsed: number;
  licenseUsage: Record<LicenseType, number>;
}

export interface CustomerRole {
  roleId: EntityId;
  quota: number;
}

export interface BaseCustomerEntity {
  approvalWorkflow: CustomerFlag;
  cmwUrl: string;
  cpwebUrl: string | null;
  cpwebVersion: string;
  customLoginPrefix: string | null;
  description: string;
  devicesQuota: number;
  iFramesQuota?: number;
  eulaEnabled: CustomerFlag;
  feedsEnabled: CustomerFlag;
  ifAlias: string | null;
  ifUrl: string | null;
  internal: CustomerFlag;
  name: string;
  sfAccountNo: string;
  sfBillingAccountNo: string;
  storageQuota: number;
  trial: CustomerFlag;

  /**
   * A custom playlist update interval for the customer. This is only for Bank
   * of America at this time.
   */
  playlistUpdateInterval: string;

  /**
   * A custom channel update interval for the customer. This is only for Bank
   * of America at this time.
   */
  channelsUpdateInterval: string;
}

export interface CustomerEntity extends BaseCustomerEntity {
  cognitoKey: string;
  /**
   * The customer entity uses "createdAt" instead of "createdOn".
   */
  createdAt: ISOTimestamp;
  createdBy: EntityId;
  customerId: EntityId;
  dataKey: string;
  providerId: string;
  status: CustomerStatus;

  /**
   * The customer entity uses "updatedAt" instead of "modifiedOn".
   */
  updatedAt: ISOTimestamp;

  /**
   * The customer entity uses "updatedBy" instead of "modifiedBy".
   */
  updatedBy: EntityId;
  usersQuota: number;

  /**
   * The market solution and appmaker ids that the customer has been granted access to.
   */
  entitlements?: CustomerEntitlements;
  roles?: CustomerRole[];
  customerUsage?: CustomerUsage;
}

export interface CreateCustomerData extends BaseCustomerEntity {
  roles: Record<string, CustomerRole[]>;
  admins: CustomerAdmin[];
}

/**
 * A normalized customer entity that can be provided to shared components
 * for standardized rendering.
 */
export interface ListViewCustomerEntity
  extends Pick<CustomerEntity, 'name' | 'description' | 'customerId'>,
    Omit<EntityMetadata, 'companyId'> {
  /**
   * The customerId, normalized as id
   */
  id: EntityId;

  /**
   * An item type is required for rendering. Customers don't have a
   * type so a static string of 'customer' is provided.
   */
  type: 'customer';

  /**
   * Pathname that will point to showing a customer's super modal.
   */
  to: string | null;
}

export interface CustomerStatusCount {
  total: number;
  active: number;
  failed: number;
  pending: number;
  suspended: number;
}

export interface CustomersState
  extends EntityState<CustomerEntity>,
    CustomerStatusCount,
    SortState {
  offset: number;
  numberOfItems: number;
  itemList: readonly EntityId[];
  loading: boolean;
  loadingIds: readonly EntityId[];
  loadingCount: boolean;
  search: LegacySearchState;
}
