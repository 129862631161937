import { lazy, ReactElement } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { isMobile, useAppSelector } from 'appState';
import { NullSuspense, ProgressSuspense } from 'components/LazyLoading';
import SecureRoute from 'components/SecureRoute';
import {
  ADMIN,
  ADMIN_USERS,
  ADMIN_SETTINGS,
  CUSTOMERS,
  isAlerts,
  isLoginOrLogout,
  REPORTING,
} from 'utils/routes';

import styles from './AppDrawerContent.module.scss';

const id = 'main-navigation';
const CustomersNavigation = lazy(() => import('./CustomersNavigation'));
const AdminNavigation = lazy(() => import('./AdminNavigation'));
const ReportingNavigation = lazy(() => import('./ReportingNavigation'));
const Licenses = lazy(() => import('./Licenses'));
const ChannelShares = lazy(() => import('./ChannelShares'));

export default function AppDrawerContent(): ReactElement | null {
  const { pathname } = useLocation();
  const mobile = useAppSelector(isMobile);
  if (isLoginOrLogout(pathname) || isAlerts(pathname)) {
    return null;
  }

  return (
    <>
      <ul id={id} className={styles.list}>
        <ProgressSuspense id="loading-navigation">
          <Route path={CUSTOMERS}>
            <CustomersNavigation id={id} />
          </Route>
          <Route path={ADMIN}>
            <AdminNavigation id={id} />
          </Route>
          <Route path={REPORTING}>
            <ReportingNavigation id={id} />
          </Route>
        </ProgressSuspense>
      </ul>
      <NullSuspense>
        <Route path={ADMIN_USERS}>
          <Licenses />
        </Route>
        {!mobile && (
          <SecureRoute path={ADMIN_SETTINGS}>
            <ChannelShares />
          </SecureRoute>
        )}
      </NullSuspense>
    </>
  );
}
