import { createReducer } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import {
  USERS_INITIAL_STATE,
  INITIAL_OFFSET_STATE,
  INITIAL_LOADING_STATE,
  HOME,
} from 'constants/pagination';
import { fetchUsers, postUser, deleteUser } from '../users';
import { resolveItemList } from './utils';

export default createReducer(USERS_INITIAL_STATE, (builder) => {
  builder.addCase(fetchUsers.pending, (state, action) => {
    const { paginationLocation, offset } = action.meta.arg;

    if (paginationLocation !== HOME) {
      return;
    }
    state.loading = !INITIAL_LOADING_STATE;

    if (offset === INITIAL_OFFSET_STATE) {
      state.offset = INITIAL_OFFSET_STATE;
      state.itemList = [];
    }
  });

  builder.addCase(fetchUsers.fulfilled, (state, action) => {
    if (action.meta.arg.paginationLocation !== HOME) {
      return;
    }

    const { payload } = action;
    const {
      offset,
      sort,
      sortOrder,
      search,
      licenseTypes,
      lastAccessTypes,
      groups,
      numberOfItems,
      items,
    } = payload;

    state.offset = offset ?? INITIAL_OFFSET_STATE;
    state.loading = INITIAL_LOADING_STATE;

    state.numberOfItems = numberOfItems;

    // itemList changes for a general fetch
    state.itemList = resolveItemList(state, items);

    const sortState = {
      sort: sort || state.sort.sort,
      sortOrder: sortOrder || state.sort.sortOrder,
      isSorted: offset === 0 || state.sort.isSorted,
    };
    state.sort = sortState;

    // filter things
    if (!isEqual(state.search, search)) {
      state.search = search || '';
    }

    if (!isEqual(state.licenseTypes, licenseTypes)) {
      state.licenseTypes = licenseTypes || [];
    }

    if (!isEqual(state.lastAccessTypes, lastAccessTypes)) {
      state.lastAccessTypes = lastAccessTypes || [];
    }

    if (!isEqual(state.groups, groups)) {
      state.groups = groups || [];
    }
  });

  builder.addCase(fetchUsers.rejected, (state, action) => {
    if (action.meta.arg.paginationLocation !== HOME) {
      return;
    }
    state.loading = INITIAL_LOADING_STATE;
  });

  builder.addCase(postUser.fulfilled, (state, action) => {
    const { id } = action.payload;

    if (id) {
      state.itemList = [id, ...state.itemList];
      state.sort.isSorted = false;
      state.numberOfItems += 1;
    }
  });

  builder.addCase(
    deleteUser.fulfilled,
    (
      state,
      {
        meta: {
          arg: { userId },
        },
      }
    ) => {
      if (userId && state.itemList.includes(userId)) {
        state.itemList = state.itemList.filter((item) => item !== userId);
        state.numberOfItems -= 1;
      }
    }
  );
});
