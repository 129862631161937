/* eslint-disable @typescript-eslint/no-explicit-any */
import { MODULE_KEYS, SYS_NAME_KEYS, SYS_NAMES } from 'fwi-constants';
import { EntityId, SortState, ValuesOf } from 'fwi-fe-types';

import {
  NONE,
  ONE_DAY,
  ONE_YEAR,
} from 'components/SearchFiltersDrawer/constants';

export interface AdminRouteParams {
  groupId?: string;
  userId?: string;
}

export interface SearchFiltersState {
  visible: boolean;
}

export interface SharedPaginationState {
  offset: number;
  loading: boolean;
  numberOfItems: number;
  itemList: readonly string[];
}

export interface SharedSortable {
  sort: SortState;
}

export const LicenseType = {
  AUTHOR: 'AUTHOR',
  VIEWER: 'VIEWER',
  CONTRIBUTOR: 'CONTRIBUTOR',
} as const;
export type LicenseType = ValuesOf<typeof LicenseType>;

export const LicenseTypeFilter = {
  AUTHOR: 'AUTHOR',
  VIEWER: 'VIEWER',
  CONTRIBUTOR: 'CONTRIBUTOR',
  ADMIN: 'ADMIN',
} as const;
export type LicenseTypeFilter = ValuesOf<typeof LicenseTypeFilter>;

export type LastAccessType = typeof NONE | typeof ONE_DAY | typeof ONE_YEAR;

export interface UserFilters {
  licenseTypes: LicenseTypeFilter[];
  lastAccessTypes: LastAccessType[];
  groups: EntityId[];
}

export interface FilterableUsers extends UserFilters {
  search: string;
}

export interface PaginationState {
  homeUsers: SharedPaginationState & SharedSortable & FilterableUsers;
  homeGroups: SharedPaginationState & SharedSortable;
  homeLabels: Pick<SharedPaginationState, 'loading' | 'numberOfItems'> &
    SharedSortable;
  groupModalUsers: SharedPaginationState;
  userModalGroups: SharedPaginationState;
  filterGroups: SharedPaginationState & SharedSortable;
}

export type RestrictableModuleAccess =
  | typeof MODULE_KEYS.DEVICE_MANAGEMENT
  | typeof MODULE_KEYS.CONTENT_MANAGER_WEB
  | typeof MODULE_KEYS.CONTENT_LIBRARY
  | typeof MODULE_KEYS.CHANNELS
  | typeof MODULE_KEYS.REPORTING;

export const RESTRICTABLE_MODULE_ACCESS_NAME: Record<
  RestrictableModuleAccess,
  SYS_NAMES
> = {
  DEVICE_MANAGEMENT: SYS_NAME_KEYS.DeviceManagement,
  CONTENT_LIBRARY: SYS_NAME_KEYS.Library,
  CHANNELS: SYS_NAME_KEYS.Channels,
  CONTENT_MANAGER_WEB: SYS_NAME_KEYS.ContentManagerWeb,
  REPORTING: SYS_NAME_KEYS.Reporting,
};

export const ModuleAccess = {
  Allow: 'allow',
  Deny: 'deny',
  Limited: 'limited',
  Everything: '*',
} as const;
export type ModuleAccess = ValuesOf<typeof ModuleAccess>;

export interface EmbedDomain {
  id?: number;
  domain: string;
  active: boolean;
  isSharePoint: boolean;
}

export interface PlaybackReporting {
  value: boolean;
  lastModified?: string;
}

export interface CompanySettings {
  publicUrl: boolean;
  embedDomains: EmbedDomain[];
  feedsEnabled: boolean;
  playbackReportingEnabled: PlaybackReporting;
  isReportsEnabledForTheFirstTime: boolean;
}

export interface LicenseQuota {
  quotaCap: number;
  quotaUsed: number;
  quotaRemaining: number;
}

export type LicenseTypeWithQuota =
  | typeof LicenseType.AUTHOR
  | typeof LicenseType.CONTRIBUTOR
  | typeof LicenseType.VIEWER;

export type LicenseQuotas = {
  [key in LicenseTypeWithQuota]: LicenseQuota;
};

export interface LicenseQuotasState extends LicenseQuotas {
  loading: boolean;
}
